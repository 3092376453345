import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { amplifyFetcher } from './fetcher';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: any;
  AWSJSON: string;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: string;
  AWSURL: string;
  BigInt: any;
  Double: any;
};

export type ApplicationForm = {
  __typename?: 'ApplicationForm';
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  request: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<UserInput>;
  userID: Scalars['ID'];
};

export type CreateApplicationFormInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  request: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID: Scalars['ID'];
};

export type CreateUserInput = {
  age?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  industryGroup?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  needs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snsType: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateVContentInput = {
  VFaceID: Scalars['ID'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  fileName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isBookmarked: Scalars['Boolean'];
  rating?: InputMaybe<Scalars['Int']>;
  storage: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID: Scalars['ID'];
};

export type CreateVFaceInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  image: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateWithdrawalInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  reason: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID: Scalars['ID'];
};

export type DeleteApplicationFormInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteVContentInput = {
  id: Scalars['ID'];
};

export type DeleteVFaceInput = {
  id: Scalars['ID'];
};

export type DeleteWithdrawalInput = {
  id: Scalars['ID'];
};

export type ModelApplicationFormConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelApplicationFormConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelApplicationFormConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelApplicationFormConditionInput>>>;
  phoneNumber?: InputMaybe<ModelStringInput>;
  request?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export type ModelApplicationFormConnection = {
  __typename?: 'ModelApplicationFormConnection';
  items: Array<Maybe<ApplicationForm>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelApplicationFormFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelApplicationFormFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelApplicationFormFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelApplicationFormFilterInput>>>;
  phoneNumber?: InputMaybe<ModelStringInput>;
  request?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
};

export type ModelSubscriptionApplicationFormFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionApplicationFormFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  email?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionApplicationFormFilterInput>>>;
  phoneNumber?: InputMaybe<ModelSubscriptionStringInput>;
  request?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionBooleanInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelSubscriptionFloatInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type ModelSubscriptionIdInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ModelSubscriptionIntInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ModelSubscriptionStringInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ModelSubscriptionUserFilterInput = {
  age?: InputMaybe<ModelSubscriptionStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUserFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  email?: InputMaybe<ModelSubscriptionStringInput>;
  gender?: InputMaybe<ModelSubscriptionStringInput>;
  industryGroup?: InputMaybe<ModelSubscriptionStringInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  needs?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUserFilterInput>>>;
  snsType?: InputMaybe<ModelSubscriptionStringInput>;
  source?: InputMaybe<ModelSubscriptionStringInput>;
  title?: InputMaybe<ModelSubscriptionStringInput>;
  type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionVContentFilterInput = {
  VFaceID?: InputMaybe<ModelSubscriptionIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionVContentFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  fileName?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  isBookmarked?: InputMaybe<ModelSubscriptionBooleanInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionVContentFilterInput>>>;
  rating?: InputMaybe<ModelSubscriptionIntInput>;
  storage?: InputMaybe<ModelSubscriptionStringInput>;
  type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionVFaceFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionVFaceFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  gender?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  image?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionVFaceFilterInput>>>;
  type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionWithdrawalFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionWithdrawalFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionWithdrawalFilterInput>>>;
  reason?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelUserConditionInput = {
  age?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelUserConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  gender?: InputMaybe<ModelStringInput>;
  industryGroup?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  needs?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUserConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserConditionInput>>>;
  snsType?: InputMaybe<ModelStringInput>;
  source?: InputMaybe<ModelStringInput>;
  title?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUserConnection = {
  __typename?: 'ModelUserConnection';
  items: Array<Maybe<User>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFilterInput = {
  age?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  gender?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  industryGroup?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  needs?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUserFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  snsType?: InputMaybe<ModelStringInput>;
  source?: InputMaybe<ModelStringInput>;
  title?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelVContentConditionInput = {
  VFaceID?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelVContentConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  fileName?: InputMaybe<ModelStringInput>;
  isBookmarked?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelVContentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelVContentConditionInput>>>;
  rating?: InputMaybe<ModelIntInput>;
  storage?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export type ModelVContentConnection = {
  __typename?: 'ModelVContentConnection';
  items: Array<Maybe<VContent>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVContentFilterInput = {
  VFaceID?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelVContentFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  fileName?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  isBookmarked?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelVContentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelVContentFilterInput>>>;
  rating?: InputMaybe<ModelIntInput>;
  storage?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export type ModelVFaceConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelVFaceConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  gender?: InputMaybe<ModelStringInput>;
  image?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelVFaceConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelVFaceConditionInput>>>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelVFaceConnection = {
  __typename?: 'ModelVFaceConnection';
  items: Array<Maybe<VFace>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVFaceFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelVFaceFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  gender?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  image?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelVFaceFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelVFaceFilterInput>>>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelWithdrawalConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelWithdrawalConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelWithdrawalConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelWithdrawalConditionInput>>>;
  reason?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export type ModelWithdrawalConnection = {
  __typename?: 'ModelWithdrawalConnection';
  items: Array<Maybe<Withdrawal>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelWithdrawalFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelWithdrawalFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelWithdrawalFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelWithdrawalFilterInput>>>;
  reason?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  userID?: InputMaybe<ModelIdInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApplicationForm?: Maybe<ApplicationForm>;
  createUser?: Maybe<User>;
  createVContent?: Maybe<VContent>;
  createVFace?: Maybe<VFace>;
  createWithdrawal?: Maybe<Withdrawal>;
  deleteApplicationForm?: Maybe<ApplicationForm>;
  deleteUser?: Maybe<User>;
  deleteVContent?: Maybe<VContent>;
  deleteVFace?: Maybe<VFace>;
  deleteWithdrawal?: Maybe<Withdrawal>;
  updateApplicationForm?: Maybe<ApplicationForm>;
  updateUser?: Maybe<User>;
  updateVContent?: Maybe<VContent>;
  updateVFace?: Maybe<VFace>;
  updateWithdrawal?: Maybe<Withdrawal>;
};


export type MutationCreateApplicationFormArgs = {
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
  input: CreateApplicationFormInput;
};


export type MutationCreateUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: CreateUserInput;
};


export type MutationCreateVContentArgs = {
  condition?: InputMaybe<ModelVContentConditionInput>;
  input: CreateVContentInput;
};


export type MutationCreateVFaceArgs = {
  condition?: InputMaybe<ModelVFaceConditionInput>;
  input: CreateVFaceInput;
};


export type MutationCreateWithdrawalArgs = {
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
  input: CreateWithdrawalInput;
};


export type MutationDeleteApplicationFormArgs = {
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
  input: DeleteApplicationFormInput;
};


export type MutationDeleteUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: DeleteUserInput;
};


export type MutationDeleteVContentArgs = {
  condition?: InputMaybe<ModelVContentConditionInput>;
  input: DeleteVContentInput;
};


export type MutationDeleteVFaceArgs = {
  condition?: InputMaybe<ModelVFaceConditionInput>;
  input: DeleteVFaceInput;
};


export type MutationDeleteWithdrawalArgs = {
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
  input: DeleteWithdrawalInput;
};


export type MutationUpdateApplicationFormArgs = {
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
  input: UpdateApplicationFormInput;
};


export type MutationUpdateUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: UpdateUserInput;
};


export type MutationUpdateVContentArgs = {
  condition?: InputMaybe<ModelVContentConditionInput>;
  input: UpdateVContentInput;
};


export type MutationUpdateVFaceArgs = {
  condition?: InputMaybe<ModelVFaceConditionInput>;
  input: UpdateVFaceInput;
};


export type MutationUpdateWithdrawalArgs = {
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
  input: UpdateWithdrawalInput;
};

export type Query = {
  __typename?: 'Query';
  SurveyByUser?: Maybe<ModelApplicationFormConnection>;
  UserByEmail?: Maybe<ModelUserConnection>;
  VContentByUser?: Maybe<ModelVContentConnection>;
  WithdrawalByUser?: Maybe<ModelWithdrawalConnection>;
  getApplicationForm?: Maybe<ApplicationForm>;
  getUser?: Maybe<User>;
  getVContent?: Maybe<VContent>;
  getVFace?: Maybe<VFace>;
  getWithdrawal?: Maybe<Withdrawal>;
  listApplicationForms?: Maybe<ModelApplicationFormConnection>;
  listUsers?: Maybe<ModelUserConnection>;
  listVContents?: Maybe<ModelVContentConnection>;
  listVFaces?: Maybe<ModelVFaceConnection>;
  listWithdrawals?: Maybe<ModelWithdrawalConnection>;
  vContentByVFace?: Maybe<ModelVContentConnection>;
  vFaceByType?: Maybe<ModelVFaceConnection>;
};


export type QuerySurveyByUserArgs = {
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelApplicationFormFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userID: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  email: Scalars['String'];
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryVContentByUserArgs = {
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userID: Scalars['ID'];
};


export type QueryWithdrawalByUserArgs = {
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelWithdrawalFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userID: Scalars['ID'];
};


export type QueryGetApplicationFormArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetVContentArgs = {
  id: Scalars['ID'];
};


export type QueryGetVFaceArgs = {
  id: Scalars['ID'];
};


export type QueryGetWithdrawalArgs = {
  id: Scalars['ID'];
};


export type QueryListApplicationFormsArgs = {
  filter?: InputMaybe<ModelApplicationFormFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListVContentsArgs = {
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListVFacesArgs = {
  filter?: InputMaybe<ModelVFaceFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListWithdrawalsArgs = {
  filter?: InputMaybe<ModelWithdrawalFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryVContentByVFaceArgs = {
  VFaceID: Scalars['ID'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryVFaceByTypeArgs = {
  filter?: InputMaybe<ModelVFaceFilterInput>;
  image?: InputMaybe<ModelStringKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  type: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateApplicationForm?: Maybe<ApplicationForm>;
  onCreateUser?: Maybe<User>;
  onCreateVContent?: Maybe<VContent>;
  onCreateVFace?: Maybe<VFace>;
  onCreateWithdrawal?: Maybe<Withdrawal>;
  onDeleteApplicationForm?: Maybe<ApplicationForm>;
  onDeleteUser?: Maybe<User>;
  onDeleteVContent?: Maybe<VContent>;
  onDeleteVFace?: Maybe<VFace>;
  onDeleteWithdrawal?: Maybe<Withdrawal>;
  onUpdateApplicationForm?: Maybe<ApplicationForm>;
  onUpdateUser?: Maybe<User>;
  onUpdateVContent?: Maybe<VContent>;
  onUpdateVFace?: Maybe<VFace>;
  onUpdateWithdrawal?: Maybe<Withdrawal>;
};


export type SubscriptionOnCreateApplicationFormArgs = {
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreateUserArgs = {
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreateVContentArgs = {
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreateVFaceArgs = {
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
};


export type SubscriptionOnCreateWithdrawalArgs = {
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
};


export type SubscriptionOnDeleteApplicationFormArgs = {
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeleteUserArgs = {
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeleteVContentArgs = {
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeleteVFaceArgs = {
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
};


export type SubscriptionOnDeleteWithdrawalArgs = {
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
};


export type SubscriptionOnUpdateApplicationFormArgs = {
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdateUserArgs = {
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdateVContentArgs = {
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdateVFaceArgs = {
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
};


export type SubscriptionOnUpdateWithdrawalArgs = {
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
};

export type UpdateApplicationFormInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserInput = {
  age?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  industryGroup?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  needs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snsType?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateVContentInput = {
  VFaceID?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  fileName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isBookmarked?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['Int']>;
  storage?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID?: InputMaybe<Scalars['ID']>;
};

export type UpdateVFaceInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateWithdrawalInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  user?: InputMaybe<UserInputInput>;
  userID?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  age?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industryGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  needs?: Maybe<Array<Maybe<Scalars['String']>>>;
  snsType: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type UserInput = {
  __typename?: 'UserInput';
  age?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industryGroup?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  needs?: Maybe<Array<Maybe<Scalars['String']>>>;
  snsType: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UserInputInput = {
  age?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  industryGroup?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  needs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snsType: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type VContent = {
  __typename?: 'VContent';
  VFaceID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  isBookmarked: Scalars['Boolean'];
  rating?: Maybe<Scalars['Int']>;
  storage: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<UserInput>;
  userID: Scalars['ID'];
};

export type VFace = {
  __typename?: 'VFace';
  createdAt: Scalars['AWSDateTime'];
  gender: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<UserInput>;
  userID: Scalars['ID'];
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
  condition?: InputMaybe<ModelUserConditionInput>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
  condition?: InputMaybe<ModelUserConditionInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
  condition?: InputMaybe<ModelUserConditionInput>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type CreateVFaceMutationVariables = Exact<{
  input: CreateVFaceInput;
  condition?: InputMaybe<ModelVFaceConditionInput>;
}>;


export type CreateVFaceMutation = { __typename?: 'Mutation', createVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type UpdateVFaceMutationVariables = Exact<{
  input: UpdateVFaceInput;
  condition?: InputMaybe<ModelVFaceConditionInput>;
}>;


export type UpdateVFaceMutation = { __typename?: 'Mutation', updateVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type DeleteVFaceMutationVariables = Exact<{
  input: DeleteVFaceInput;
  condition?: InputMaybe<ModelVFaceConditionInput>;
}>;


export type DeleteVFaceMutation = { __typename?: 'Mutation', deleteVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type CreateVContentMutationVariables = Exact<{
  input: CreateVContentInput;
  condition?: InputMaybe<ModelVContentConditionInput>;
}>;


export type CreateVContentMutation = { __typename?: 'Mutation', createVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateVContentMutationVariables = Exact<{
  input: UpdateVContentInput;
  condition?: InputMaybe<ModelVContentConditionInput>;
}>;


export type UpdateVContentMutation = { __typename?: 'Mutation', updateVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type DeleteVContentMutationVariables = Exact<{
  input: DeleteVContentInput;
  condition?: InputMaybe<ModelVContentConditionInput>;
}>;


export type DeleteVContentMutation = { __typename?: 'Mutation', deleteVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateApplicationFormMutationVariables = Exact<{
  input: CreateApplicationFormInput;
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
}>;


export type CreateApplicationFormMutation = { __typename?: 'Mutation', createApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateApplicationFormMutationVariables = Exact<{
  input: UpdateApplicationFormInput;
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
}>;


export type UpdateApplicationFormMutation = { __typename?: 'Mutation', updateApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type DeleteApplicationFormMutationVariables = Exact<{
  input: DeleteApplicationFormInput;
  condition?: InputMaybe<ModelApplicationFormConditionInput>;
}>;


export type DeleteApplicationFormMutation = { __typename?: 'Mutation', deleteApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateWithdrawalMutationVariables = Exact<{
  input: CreateWithdrawalInput;
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
}>;


export type CreateWithdrawalMutation = { __typename?: 'Mutation', createWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateWithdrawalMutationVariables = Exact<{
  input: UpdateWithdrawalInput;
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
}>;


export type UpdateWithdrawalMutation = { __typename?: 'Mutation', updateWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type DeleteWithdrawalMutationVariables = Exact<{
  input: DeleteWithdrawalInput;
  condition?: InputMaybe<ModelWithdrawalConditionInput>;
}>;


export type DeleteWithdrawalMutation = { __typename?: 'Mutation', deleteWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type ListUsersQueryVariables = Exact<{
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUsers?: { __typename?: 'ModelUserConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined> } | null | undefined };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type UserByEmailQuery = { __typename?: 'Query', UserByEmail?: { __typename?: 'ModelUserConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined> } | null | undefined };

export type GetVFaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetVFaceQuery = { __typename?: 'Query', getVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type ListVFacesQueryVariables = Exact<{
  filter?: InputMaybe<ModelVFaceFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListVFacesQuery = { __typename?: 'Query', listVFaces?: { __typename?: 'ModelVFaceConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined> } | null | undefined };

export type VFaceByTypeQueryVariables = Exact<{
  type: Scalars['String'];
  image?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelVFaceFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type VFaceByTypeQuery = { __typename?: 'Query', vFaceByType?: { __typename?: 'ModelVFaceConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined> } | null | undefined };

export type GetVContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetVContentQuery = { __typename?: 'Query', getVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type ListVContentsQueryVariables = Exact<{
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListVContentsQuery = { __typename?: 'Query', listVContents?: { __typename?: 'ModelVContentConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type VContentByUserQueryVariables = Exact<{
  userID: Scalars['ID'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type VContentByUserQuery = { __typename?: 'Query', VContentByUser?: { __typename?: 'ModelVContentConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type VContentByVFaceQueryVariables = Exact<{
  VFaceID: Scalars['ID'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelVContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type VContentByVFaceQuery = { __typename?: 'Query', vContentByVFace?: { __typename?: 'ModelVContentConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetApplicationFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetApplicationFormQuery = { __typename?: 'Query', getApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type ListApplicationFormsQueryVariables = Exact<{
  filter?: InputMaybe<ModelApplicationFormFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListApplicationFormsQuery = { __typename?: 'Query', listApplicationForms?: { __typename?: 'ModelApplicationFormConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type SurveyByUserQueryVariables = Exact<{
  userID: Scalars['ID'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelApplicationFormFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type SurveyByUserQuery = { __typename?: 'Query', SurveyByUser?: { __typename?: 'ModelApplicationFormConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetWithdrawalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWithdrawalQuery = { __typename?: 'Query', getWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type ListWithdrawalsQueryVariables = Exact<{
  filter?: InputMaybe<ModelWithdrawalFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListWithdrawalsQuery = { __typename?: 'Query', listWithdrawals?: { __typename?: 'ModelWithdrawalConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type WithdrawalByUserQueryVariables = Exact<{
  userID: Scalars['ID'];
  createdAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelWithdrawalFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type WithdrawalByUserQuery = { __typename?: 'Query', WithdrawalByUser?: { __typename?: 'ModelWithdrawalConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type OnCreateUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type OnCreateUserSubscription = { __typename?: 'Subscription', onCreateUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnUpdateUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type OnUpdateUserSubscription = { __typename?: 'Subscription', onUpdateUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnDeleteUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionUserFilterInput>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type OnDeleteUserSubscription = { __typename?: 'Subscription', onDeleteUser?: { __typename?: 'User', id: string, email: string, snsType: string, type?: string | null | undefined, name?: string | null | undefined, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined, source?: string | null | undefined, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnCreateVFaceSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
}>;


export type OnCreateVFaceSubscription = { __typename?: 'Subscription', onCreateVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnUpdateVFaceSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
}>;


export type OnUpdateVFaceSubscription = { __typename?: 'Subscription', onUpdateVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnDeleteVFaceSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVFaceFilterInput>;
}>;


export type OnDeleteVFaceSubscription = { __typename?: 'Subscription', onDeleteVFace?: { __typename?: 'VFace', id: string, image: string, gender: string, type: string, createdAt: Date, updatedAt: Date } | null | undefined };

export type OnCreateVContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnCreateVContentSubscription = { __typename?: 'Subscription', onCreateVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnUpdateVContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnUpdateVContentSubscription = { __typename?: 'Subscription', onUpdateVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnDeleteVContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionVContentFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnDeleteVContentSubscription = { __typename?: 'Subscription', onDeleteVContent?: { __typename?: 'VContent', id: string, userID: string, VFaceID: string, fileName: string, storage: string, isBookmarked: boolean, type: string, rating?: number | null | undefined, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnCreateApplicationFormSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnCreateApplicationFormSubscription = { __typename?: 'Subscription', onCreateApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnUpdateApplicationFormSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnUpdateApplicationFormSubscription = { __typename?: 'Subscription', onUpdateApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnDeleteApplicationFormSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionApplicationFormFilterInput>;
  userID?: InputMaybe<Scalars['String']>;
}>;


export type OnDeleteApplicationFormSubscription = { __typename?: 'Subscription', onDeleteApplicationForm?: { __typename?: 'ApplicationForm', id: string, userID: string, name: string, phoneNumber: string, email: string, request: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnCreateWithdrawalSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
}>;


export type OnCreateWithdrawalSubscription = { __typename?: 'Subscription', onCreateWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnUpdateWithdrawalSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
}>;


export type OnUpdateWithdrawalSubscription = { __typename?: 'Subscription', onUpdateWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };

export type OnDeleteWithdrawalSubscriptionVariables = Exact<{
  filter?: InputMaybe<ModelSubscriptionWithdrawalFilterInput>;
}>;


export type OnDeleteWithdrawalSubscription = { __typename?: 'Subscription', onDeleteWithdrawal?: { __typename?: 'Withdrawal', id: string, userID: string, reason: string, createdAt: Date, updatedAt: Date, user?: { __typename?: 'UserInput', id: string, email: string, snsType: string, type: string, name: string, age?: string | null | undefined, gender?: string | null | undefined, needs?: Array<string | null | undefined> | null | undefined, industryGroup?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };


export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
  createUser(input: $input, condition: $condition) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['CreateUser'],
      (variables?: CreateUserMutationVariables) => amplifyFetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => amplifyFetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => amplifyFetcher<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
      options
    );
export const CreateVFaceDocument = `
    mutation CreateVFace($input: CreateVFaceInput!, $condition: ModelVFaceConditionInput) {
  createVFace(input: $input, condition: $condition) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const useCreateVFaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVFaceMutation, TError, CreateVFaceMutationVariables, TContext>) =>
    useMutation<CreateVFaceMutation, TError, CreateVFaceMutationVariables, TContext>(
      ['CreateVFace'],
      (variables?: CreateVFaceMutationVariables) => amplifyFetcher<CreateVFaceMutation, CreateVFaceMutationVariables>(CreateVFaceDocument, variables)(),
      options
    );
export const UpdateVFaceDocument = `
    mutation UpdateVFace($input: UpdateVFaceInput!, $condition: ModelVFaceConditionInput) {
  updateVFace(input: $input, condition: $condition) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateVFaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateVFaceMutation, TError, UpdateVFaceMutationVariables, TContext>) =>
    useMutation<UpdateVFaceMutation, TError, UpdateVFaceMutationVariables, TContext>(
      ['UpdateVFace'],
      (variables?: UpdateVFaceMutationVariables) => amplifyFetcher<UpdateVFaceMutation, UpdateVFaceMutationVariables>(UpdateVFaceDocument, variables)(),
      options
    );
export const DeleteVFaceDocument = `
    mutation DeleteVFace($input: DeleteVFaceInput!, $condition: ModelVFaceConditionInput) {
  deleteVFace(input: $input, condition: $condition) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteVFaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteVFaceMutation, TError, DeleteVFaceMutationVariables, TContext>) =>
    useMutation<DeleteVFaceMutation, TError, DeleteVFaceMutationVariables, TContext>(
      ['DeleteVFace'],
      (variables?: DeleteVFaceMutationVariables) => amplifyFetcher<DeleteVFaceMutation, DeleteVFaceMutationVariables>(DeleteVFaceDocument, variables)(),
      options
    );
export const CreateVContentDocument = `
    mutation CreateVContent($input: CreateVContentInput!, $condition: ModelVContentConditionInput) {
  createVContent(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const useCreateVContentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVContentMutation, TError, CreateVContentMutationVariables, TContext>) =>
    useMutation<CreateVContentMutation, TError, CreateVContentMutationVariables, TContext>(
      ['CreateVContent'],
      (variables?: CreateVContentMutationVariables) => amplifyFetcher<CreateVContentMutation, CreateVContentMutationVariables>(CreateVContentDocument, variables)(),
      options
    );
export const UpdateVContentDocument = `
    mutation UpdateVContent($input: UpdateVContentInput!, $condition: ModelVContentConditionInput) {
  updateVContent(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateVContentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateVContentMutation, TError, UpdateVContentMutationVariables, TContext>) =>
    useMutation<UpdateVContentMutation, TError, UpdateVContentMutationVariables, TContext>(
      ['UpdateVContent'],
      (variables?: UpdateVContentMutationVariables) => amplifyFetcher<UpdateVContentMutation, UpdateVContentMutationVariables>(UpdateVContentDocument, variables)(),
      options
    );
export const DeleteVContentDocument = `
    mutation DeleteVContent($input: DeleteVContentInput!, $condition: ModelVContentConditionInput) {
  deleteVContent(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteVContentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteVContentMutation, TError, DeleteVContentMutationVariables, TContext>) =>
    useMutation<DeleteVContentMutation, TError, DeleteVContentMutationVariables, TContext>(
      ['DeleteVContent'],
      (variables?: DeleteVContentMutationVariables) => amplifyFetcher<DeleteVContentMutation, DeleteVContentMutationVariables>(DeleteVContentDocument, variables)(),
      options
    );
export const CreateApplicationFormDocument = `
    mutation CreateApplicationForm($input: CreateApplicationFormInput!, $condition: ModelApplicationFormConditionInput) {
  createApplicationForm(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const useCreateApplicationFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateApplicationFormMutation, TError, CreateApplicationFormMutationVariables, TContext>) =>
    useMutation<CreateApplicationFormMutation, TError, CreateApplicationFormMutationVariables, TContext>(
      ['CreateApplicationForm'],
      (variables?: CreateApplicationFormMutationVariables) => amplifyFetcher<CreateApplicationFormMutation, CreateApplicationFormMutationVariables>(CreateApplicationFormDocument, variables)(),
      options
    );
export const UpdateApplicationFormDocument = `
    mutation UpdateApplicationForm($input: UpdateApplicationFormInput!, $condition: ModelApplicationFormConditionInput) {
  updateApplicationForm(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateApplicationFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateApplicationFormMutation, TError, UpdateApplicationFormMutationVariables, TContext>) =>
    useMutation<UpdateApplicationFormMutation, TError, UpdateApplicationFormMutationVariables, TContext>(
      ['UpdateApplicationForm'],
      (variables?: UpdateApplicationFormMutationVariables) => amplifyFetcher<UpdateApplicationFormMutation, UpdateApplicationFormMutationVariables>(UpdateApplicationFormDocument, variables)(),
      options
    );
export const DeleteApplicationFormDocument = `
    mutation DeleteApplicationForm($input: DeleteApplicationFormInput!, $condition: ModelApplicationFormConditionInput) {
  deleteApplicationForm(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteApplicationFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteApplicationFormMutation, TError, DeleteApplicationFormMutationVariables, TContext>) =>
    useMutation<DeleteApplicationFormMutation, TError, DeleteApplicationFormMutationVariables, TContext>(
      ['DeleteApplicationForm'],
      (variables?: DeleteApplicationFormMutationVariables) => amplifyFetcher<DeleteApplicationFormMutation, DeleteApplicationFormMutationVariables>(DeleteApplicationFormDocument, variables)(),
      options
    );
export const CreateWithdrawalDocument = `
    mutation CreateWithdrawal($input: CreateWithdrawalInput!, $condition: ModelWithdrawalConditionInput) {
  createWithdrawal(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const useCreateWithdrawalMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateWithdrawalMutation, TError, CreateWithdrawalMutationVariables, TContext>) =>
    useMutation<CreateWithdrawalMutation, TError, CreateWithdrawalMutationVariables, TContext>(
      ['CreateWithdrawal'],
      (variables?: CreateWithdrawalMutationVariables) => amplifyFetcher<CreateWithdrawalMutation, CreateWithdrawalMutationVariables>(CreateWithdrawalDocument, variables)(),
      options
    );
export const UpdateWithdrawalDocument = `
    mutation UpdateWithdrawal($input: UpdateWithdrawalInput!, $condition: ModelWithdrawalConditionInput) {
  updateWithdrawal(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateWithdrawalMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateWithdrawalMutation, TError, UpdateWithdrawalMutationVariables, TContext>) =>
    useMutation<UpdateWithdrawalMutation, TError, UpdateWithdrawalMutationVariables, TContext>(
      ['UpdateWithdrawal'],
      (variables?: UpdateWithdrawalMutationVariables) => amplifyFetcher<UpdateWithdrawalMutation, UpdateWithdrawalMutationVariables>(UpdateWithdrawalDocument, variables)(),
      options
    );
export const DeleteWithdrawalDocument = `
    mutation DeleteWithdrawal($input: DeleteWithdrawalInput!, $condition: ModelWithdrawalConditionInput) {
  deleteWithdrawal(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteWithdrawalMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteWithdrawalMutation, TError, DeleteWithdrawalMutationVariables, TContext>) =>
    useMutation<DeleteWithdrawalMutation, TError, DeleteWithdrawalMutationVariables, TContext>(
      ['DeleteWithdrawal'],
      (variables?: DeleteWithdrawalMutationVariables) => amplifyFetcher<DeleteWithdrawalMutation, DeleteWithdrawalMutationVariables>(DeleteWithdrawalDocument, variables)(),
      options
    );
export const GetUserDocument = `
    query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      variables: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      ['GetUser', variables],
      amplifyFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
      options
    );
export const useInfiniteGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUserQueryVariables,
      variables: GetUserQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetUserQuery, TError, TData>(
      ['GetUser.infinite', variables],
      (metaData) => amplifyFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListUsersDocument = `
    query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
      source
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListUsersQuery = <
      TData = ListUsersQuery,
      TError = unknown
    >(
      variables?: ListUsersQueryVariables,
      options?: UseQueryOptions<ListUsersQuery, TError, TData>
    ) =>
    useQuery<ListUsersQuery, TError, TData>(
      variables === undefined ? ['ListUsers'] : ['ListUsers', variables],
      amplifyFetcher<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, variables),
      options
    );
export const useInfiniteListUsersQuery = <
      TData = ListUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof ListUsersQueryVariables,
      variables?: ListUsersQueryVariables,
      options?: UseInfiniteQueryOptions<ListUsersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListUsersQuery, TError, TData>(
      variables === undefined ? ['ListUsers.infinite'] : ['ListUsers.infinite', variables],
      (metaData) => amplifyFetcher<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UserByEmailDocument = `
    query UserByEmail($email: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
  UserByEmail(
    email: $email
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
      source
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useUserByEmailQuery = <
      TData = UserByEmailQuery,
      TError = unknown
    >(
      variables: UserByEmailQueryVariables,
      options?: UseQueryOptions<UserByEmailQuery, TError, TData>
    ) =>
    useQuery<UserByEmailQuery, TError, TData>(
      ['UserByEmail', variables],
      amplifyFetcher<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, variables),
      options
    );
export const useInfiniteUserByEmailQuery = <
      TData = UserByEmailQuery,
      TError = unknown
    >(
      pageParamKey: keyof UserByEmailQueryVariables,
      variables: UserByEmailQueryVariables,
      options?: UseInfiniteQueryOptions<UserByEmailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<UserByEmailQuery, TError, TData>(
      ['UserByEmail.infinite', variables],
      (metaData) => amplifyFetcher<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetVFaceDocument = `
    query GetVFace($id: ID!) {
  getVFace(id: $id) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const useGetVFaceQuery = <
      TData = GetVFaceQuery,
      TError = unknown
    >(
      variables: GetVFaceQueryVariables,
      options?: UseQueryOptions<GetVFaceQuery, TError, TData>
    ) =>
    useQuery<GetVFaceQuery, TError, TData>(
      ['GetVFace', variables],
      amplifyFetcher<GetVFaceQuery, GetVFaceQueryVariables>(GetVFaceDocument, variables),
      options
    );
export const useInfiniteGetVFaceQuery = <
      TData = GetVFaceQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetVFaceQueryVariables,
      variables: GetVFaceQueryVariables,
      options?: UseInfiniteQueryOptions<GetVFaceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetVFaceQuery, TError, TData>(
      ['GetVFace.infinite', variables],
      (metaData) => amplifyFetcher<GetVFaceQuery, GetVFaceQueryVariables>(GetVFaceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListVFacesDocument = `
    query ListVFaces($filter: ModelVFaceFilterInput, $limit: Int, $nextToken: String) {
  listVFaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      image
      gender
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListVFacesQuery = <
      TData = ListVFacesQuery,
      TError = unknown
    >(
      variables?: ListVFacesQueryVariables,
      options?: UseQueryOptions<ListVFacesQuery, TError, TData>
    ) =>
    useQuery<ListVFacesQuery, TError, TData>(
      variables === undefined ? ['ListVFaces'] : ['ListVFaces', variables],
      amplifyFetcher<ListVFacesQuery, ListVFacesQueryVariables>(ListVFacesDocument, variables),
      options
    );
export const useInfiniteListVFacesQuery = <
      TData = ListVFacesQuery,
      TError = unknown
    >(
      pageParamKey: keyof ListVFacesQueryVariables,
      variables?: ListVFacesQueryVariables,
      options?: UseInfiniteQueryOptions<ListVFacesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListVFacesQuery, TError, TData>(
      variables === undefined ? ['ListVFaces.infinite'] : ['ListVFaces.infinite', variables],
      (metaData) => amplifyFetcher<ListVFacesQuery, ListVFacesQueryVariables>(ListVFacesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const VFaceByTypeDocument = `
    query VFaceByType($type: String!, $image: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVFaceFilterInput, $limit: Int, $nextToken: String) {
  vFaceByType(
    type: $type
    image: $image
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      image
      gender
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useVFaceByTypeQuery = <
      TData = VFaceByTypeQuery,
      TError = unknown
    >(
      variables: VFaceByTypeQueryVariables,
      options?: UseQueryOptions<VFaceByTypeQuery, TError, TData>
    ) =>
    useQuery<VFaceByTypeQuery, TError, TData>(
      ['VFaceByType', variables],
      amplifyFetcher<VFaceByTypeQuery, VFaceByTypeQueryVariables>(VFaceByTypeDocument, variables),
      options
    );
export const useInfiniteVFaceByTypeQuery = <
      TData = VFaceByTypeQuery,
      TError = unknown
    >(
      pageParamKey: keyof VFaceByTypeQueryVariables,
      variables: VFaceByTypeQueryVariables,
      options?: UseInfiniteQueryOptions<VFaceByTypeQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<VFaceByTypeQuery, TError, TData>(
      ['VFaceByType.infinite', variables],
      (metaData) => amplifyFetcher<VFaceByTypeQuery, VFaceByTypeQueryVariables>(VFaceByTypeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetVContentDocument = `
    query GetVContent($id: ID!) {
  getVContent(id: $id) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const useGetVContentQuery = <
      TData = GetVContentQuery,
      TError = unknown
    >(
      variables: GetVContentQueryVariables,
      options?: UseQueryOptions<GetVContentQuery, TError, TData>
    ) =>
    useQuery<GetVContentQuery, TError, TData>(
      ['GetVContent', variables],
      amplifyFetcher<GetVContentQuery, GetVContentQueryVariables>(GetVContentDocument, variables),
      options
    );
export const useInfiniteGetVContentQuery = <
      TData = GetVContentQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetVContentQueryVariables,
      variables: GetVContentQueryVariables,
      options?: UseInfiniteQueryOptions<GetVContentQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetVContentQuery, TError, TData>(
      ['GetVContent.infinite', variables],
      (metaData) => amplifyFetcher<GetVContentQuery, GetVContentQueryVariables>(GetVContentDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListVContentsDocument = `
    query ListVContents($filter: ModelVContentFilterInput, $limit: Int, $nextToken: String) {
  listVContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      VFaceID
      fileName
      storage
      isBookmarked
      type
      rating
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListVContentsQuery = <
      TData = ListVContentsQuery,
      TError = unknown
    >(
      variables?: ListVContentsQueryVariables,
      options?: UseQueryOptions<ListVContentsQuery, TError, TData>
    ) =>
    useQuery<ListVContentsQuery, TError, TData>(
      variables === undefined ? ['ListVContents'] : ['ListVContents', variables],
      amplifyFetcher<ListVContentsQuery, ListVContentsQueryVariables>(ListVContentsDocument, variables),
      options
    );
export const useInfiniteListVContentsQuery = <
      TData = ListVContentsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ListVContentsQueryVariables,
      variables?: ListVContentsQueryVariables,
      options?: UseInfiniteQueryOptions<ListVContentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListVContentsQuery, TError, TData>(
      variables === undefined ? ['ListVContents.infinite'] : ['ListVContents.infinite', variables],
      (metaData) => amplifyFetcher<ListVContentsQuery, ListVContentsQueryVariables>(ListVContentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const VContentByUserDocument = `
    query VContentByUser($userID: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVContentFilterInput, $limit: Int, $nextToken: String) {
  VContentByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      VFaceID
      fileName
      storage
      isBookmarked
      type
      rating
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useVContentByUserQuery = <
      TData = VContentByUserQuery,
      TError = unknown
    >(
      variables: VContentByUserQueryVariables,
      options?: UseQueryOptions<VContentByUserQuery, TError, TData>
    ) =>
    useQuery<VContentByUserQuery, TError, TData>(
      ['VContentByUser', variables],
      amplifyFetcher<VContentByUserQuery, VContentByUserQueryVariables>(VContentByUserDocument, variables),
      options
    );
export const useInfiniteVContentByUserQuery = <
      TData = VContentByUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof VContentByUserQueryVariables,
      variables: VContentByUserQueryVariables,
      options?: UseInfiniteQueryOptions<VContentByUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<VContentByUserQuery, TError, TData>(
      ['VContentByUser.infinite', variables],
      (metaData) => amplifyFetcher<VContentByUserQuery, VContentByUserQueryVariables>(VContentByUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const VContentByVFaceDocument = `
    query VContentByVFace($VFaceID: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVContentFilterInput, $limit: Int, $nextToken: String) {
  vContentByVFace(
    VFaceID: $VFaceID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      VFaceID
      fileName
      storage
      isBookmarked
      type
      rating
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useVContentByVFaceQuery = <
      TData = VContentByVFaceQuery,
      TError = unknown
    >(
      variables: VContentByVFaceQueryVariables,
      options?: UseQueryOptions<VContentByVFaceQuery, TError, TData>
    ) =>
    useQuery<VContentByVFaceQuery, TError, TData>(
      ['VContentByVFace', variables],
      amplifyFetcher<VContentByVFaceQuery, VContentByVFaceQueryVariables>(VContentByVFaceDocument, variables),
      options
    );
export const useInfiniteVContentByVFaceQuery = <
      TData = VContentByVFaceQuery,
      TError = unknown
    >(
      pageParamKey: keyof VContentByVFaceQueryVariables,
      variables: VContentByVFaceQueryVariables,
      options?: UseInfiniteQueryOptions<VContentByVFaceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<VContentByVFaceQuery, TError, TData>(
      ['VContentByVFace.infinite', variables],
      (metaData) => amplifyFetcher<VContentByVFaceQuery, VContentByVFaceQueryVariables>(VContentByVFaceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetApplicationFormDocument = `
    query GetApplicationForm($id: ID!) {
  getApplicationForm(id: $id) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const useGetApplicationFormQuery = <
      TData = GetApplicationFormQuery,
      TError = unknown
    >(
      variables: GetApplicationFormQueryVariables,
      options?: UseQueryOptions<GetApplicationFormQuery, TError, TData>
    ) =>
    useQuery<GetApplicationFormQuery, TError, TData>(
      ['GetApplicationForm', variables],
      amplifyFetcher<GetApplicationFormQuery, GetApplicationFormQueryVariables>(GetApplicationFormDocument, variables),
      options
    );
export const useInfiniteGetApplicationFormQuery = <
      TData = GetApplicationFormQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetApplicationFormQueryVariables,
      variables: GetApplicationFormQueryVariables,
      options?: UseInfiniteQueryOptions<GetApplicationFormQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetApplicationFormQuery, TError, TData>(
      ['GetApplicationForm.infinite', variables],
      (metaData) => amplifyFetcher<GetApplicationFormQuery, GetApplicationFormQueryVariables>(GetApplicationFormDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListApplicationFormsDocument = `
    query ListApplicationForms($filter: ModelApplicationFormFilterInput, $limit: Int, $nextToken: String) {
  listApplicationForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      name
      phoneNumber
      email
      request
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListApplicationFormsQuery = <
      TData = ListApplicationFormsQuery,
      TError = unknown
    >(
      variables?: ListApplicationFormsQueryVariables,
      options?: UseQueryOptions<ListApplicationFormsQuery, TError, TData>
    ) =>
    useQuery<ListApplicationFormsQuery, TError, TData>(
      variables === undefined ? ['ListApplicationForms'] : ['ListApplicationForms', variables],
      amplifyFetcher<ListApplicationFormsQuery, ListApplicationFormsQueryVariables>(ListApplicationFormsDocument, variables),
      options
    );
export const useInfiniteListApplicationFormsQuery = <
      TData = ListApplicationFormsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ListApplicationFormsQueryVariables,
      variables?: ListApplicationFormsQueryVariables,
      options?: UseInfiniteQueryOptions<ListApplicationFormsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListApplicationFormsQuery, TError, TData>(
      variables === undefined ? ['ListApplicationForms.infinite'] : ['ListApplicationForms.infinite', variables],
      (metaData) => amplifyFetcher<ListApplicationFormsQuery, ListApplicationFormsQueryVariables>(ListApplicationFormsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const SurveyByUserDocument = `
    query SurveyByUser($userID: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelApplicationFormFilterInput, $limit: Int, $nextToken: String) {
  SurveyByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      name
      phoneNumber
      email
      request
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useSurveyByUserQuery = <
      TData = SurveyByUserQuery,
      TError = unknown
    >(
      variables: SurveyByUserQueryVariables,
      options?: UseQueryOptions<SurveyByUserQuery, TError, TData>
    ) =>
    useQuery<SurveyByUserQuery, TError, TData>(
      ['SurveyByUser', variables],
      amplifyFetcher<SurveyByUserQuery, SurveyByUserQueryVariables>(SurveyByUserDocument, variables),
      options
    );
export const useInfiniteSurveyByUserQuery = <
      TData = SurveyByUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof SurveyByUserQueryVariables,
      variables: SurveyByUserQueryVariables,
      options?: UseInfiniteQueryOptions<SurveyByUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SurveyByUserQuery, TError, TData>(
      ['SurveyByUser.infinite', variables],
      (metaData) => amplifyFetcher<SurveyByUserQuery, SurveyByUserQueryVariables>(SurveyByUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetWithdrawalDocument = `
    query GetWithdrawal($id: ID!) {
  getWithdrawal(id: $id) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const useGetWithdrawalQuery = <
      TData = GetWithdrawalQuery,
      TError = unknown
    >(
      variables: GetWithdrawalQueryVariables,
      options?: UseQueryOptions<GetWithdrawalQuery, TError, TData>
    ) =>
    useQuery<GetWithdrawalQuery, TError, TData>(
      ['GetWithdrawal', variables],
      amplifyFetcher<GetWithdrawalQuery, GetWithdrawalQueryVariables>(GetWithdrawalDocument, variables),
      options
    );
export const useInfiniteGetWithdrawalQuery = <
      TData = GetWithdrawalQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetWithdrawalQueryVariables,
      variables: GetWithdrawalQueryVariables,
      options?: UseInfiniteQueryOptions<GetWithdrawalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetWithdrawalQuery, TError, TData>(
      ['GetWithdrawal.infinite', variables],
      (metaData) => amplifyFetcher<GetWithdrawalQuery, GetWithdrawalQueryVariables>(GetWithdrawalDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListWithdrawalsDocument = `
    query ListWithdrawals($filter: ModelWithdrawalFilterInput, $limit: Int, $nextToken: String) {
  listWithdrawals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      reason
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListWithdrawalsQuery = <
      TData = ListWithdrawalsQuery,
      TError = unknown
    >(
      variables?: ListWithdrawalsQueryVariables,
      options?: UseQueryOptions<ListWithdrawalsQuery, TError, TData>
    ) =>
    useQuery<ListWithdrawalsQuery, TError, TData>(
      variables === undefined ? ['ListWithdrawals'] : ['ListWithdrawals', variables],
      amplifyFetcher<ListWithdrawalsQuery, ListWithdrawalsQueryVariables>(ListWithdrawalsDocument, variables),
      options
    );
export const useInfiniteListWithdrawalsQuery = <
      TData = ListWithdrawalsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ListWithdrawalsQueryVariables,
      variables?: ListWithdrawalsQueryVariables,
      options?: UseInfiniteQueryOptions<ListWithdrawalsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListWithdrawalsQuery, TError, TData>(
      variables === undefined ? ['ListWithdrawals.infinite'] : ['ListWithdrawals.infinite', variables],
      (metaData) => amplifyFetcher<ListWithdrawalsQuery, ListWithdrawalsQueryVariables>(ListWithdrawalsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const WithdrawalByUserDocument = `
    query WithdrawalByUser($userID: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWithdrawalFilterInput, $limit: Int, $nextToken: String) {
  WithdrawalByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      user {
        id
        email
        snsType
        type
        name
        age
        gender
        needs
        industryGroup
        title
      }
      reason
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useWithdrawalByUserQuery = <
      TData = WithdrawalByUserQuery,
      TError = unknown
    >(
      variables: WithdrawalByUserQueryVariables,
      options?: UseQueryOptions<WithdrawalByUserQuery, TError, TData>
    ) =>
    useQuery<WithdrawalByUserQuery, TError, TData>(
      ['WithdrawalByUser', variables],
      amplifyFetcher<WithdrawalByUserQuery, WithdrawalByUserQueryVariables>(WithdrawalByUserDocument, variables),
      options
    );
export const useInfiniteWithdrawalByUserQuery = <
      TData = WithdrawalByUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof WithdrawalByUserQueryVariables,
      variables: WithdrawalByUserQueryVariables,
      options?: UseInfiniteQueryOptions<WithdrawalByUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<WithdrawalByUserQuery, TError, TData>(
      ['WithdrawalByUser.infinite', variables],
      (metaData) => amplifyFetcher<WithdrawalByUserQuery, WithdrawalByUserQueryVariables>(WithdrawalByUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const OnCreateUserDocument = `
    subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput, $id: String) {
  onCreateUser(filter: $filter, id: $id) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateUserDocument = `
    subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput, $id: String) {
  onUpdateUser(filter: $filter, id: $id) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteUserDocument = `
    subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput, $id: String) {
  onDeleteUser(filter: $filter, id: $id) {
    id
    email
    snsType
    type
    name
    age
    gender
    needs
    industryGroup
    title
    source
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateVFaceDocument = `
    subscription OnCreateVFace($filter: ModelSubscriptionVFaceFilterInput) {
  onCreateVFace(filter: $filter) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateVFaceDocument = `
    subscription OnUpdateVFace($filter: ModelSubscriptionVFaceFilterInput) {
  onUpdateVFace(filter: $filter) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteVFaceDocument = `
    subscription OnDeleteVFace($filter: ModelSubscriptionVFaceFilterInput) {
  onDeleteVFace(filter: $filter) {
    id
    image
    gender
    type
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateVContentDocument = `
    subscription OnCreateVContent($filter: ModelSubscriptionVContentFilterInput, $userID: String) {
  onCreateVContent(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateVContentDocument = `
    subscription OnUpdateVContent($filter: ModelSubscriptionVContentFilterInput, $userID: String) {
  onUpdateVContent(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteVContentDocument = `
    subscription OnDeleteVContent($filter: ModelSubscriptionVContentFilterInput, $userID: String) {
  onDeleteVContent(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    VFaceID
    fileName
    storage
    isBookmarked
    type
    rating
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateApplicationFormDocument = `
    subscription OnCreateApplicationForm($filter: ModelSubscriptionApplicationFormFilterInput, $userID: String) {
  onCreateApplicationForm(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateApplicationFormDocument = `
    subscription OnUpdateApplicationForm($filter: ModelSubscriptionApplicationFormFilterInput, $userID: String) {
  onUpdateApplicationForm(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteApplicationFormDocument = `
    subscription OnDeleteApplicationForm($filter: ModelSubscriptionApplicationFormFilterInput, $userID: String) {
  onDeleteApplicationForm(filter: $filter, userID: $userID) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    name
    phoneNumber
    email
    request
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateWithdrawalDocument = `
    subscription OnCreateWithdrawal($filter: ModelSubscriptionWithdrawalFilterInput) {
  onCreateWithdrawal(filter: $filter) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateWithdrawalDocument = `
    subscription OnUpdateWithdrawal($filter: ModelSubscriptionWithdrawalFilterInput) {
  onUpdateWithdrawal(filter: $filter) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteWithdrawalDocument = `
    subscription OnDeleteWithdrawal($filter: ModelSubscriptionWithdrawalFilterInput) {
  onDeleteWithdrawal(filter: $filter) {
    id
    userID
    user {
      id
      email
      snsType
      type
      name
      age
      gender
      needs
      industryGroup
      title
    }
    reason
    createdAt
    updatedAt
  }
}
    `;