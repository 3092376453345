import React, { useEffect, useState } from "react";
import { GreyContainer, StyledButton } from "../ui/Common";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  Add,
  ArrowForwardIos,
  Download,
  Replay,
  TouchApp,
} from "@mui/icons-material";
import guideImage from "@image/guide_image.png";
import { colors } from "@assets/styles/colors";
import ImageDialog from "../ui/ImageDialog";
import { resultType } from "./VirtualFaceStep";
import { useNavigate } from "react-router-dom";

interface VirtualFaceCompleteProps {
  type: string | undefined;
  file: File | null;
  setFile: (file: File | null) => void;
  sourceFile: File | null;
  setSourceFile: (file: File | null) => void;
  resultState: resultType[] | null;
  setResultState: (result: resultType[] | null) => void;
  setSourceImagePath: (path: string) => void;
  sourceImagePath: string;
}

const VirtualFaceComplete = (props: VirtualFaceCompleteProps) => {
  const {
    type,
    file,
    setFile,
    sourceFile,
    setSourceFile,
    resultState,
    setResultState,
    setSourceImagePath,
    sourceImagePath,
  } = props;

  const navigate = useNavigate();

  // 이미지
  const [enlargeImage, setEnlargeImage] = useState({
    open: false,
    result_url: "",
    large: false,
  });

  const handleDownload = (url: string) => {
    // const imageUrl = resultState ? resultState.url : guideImage;
    // const fileName = "test.png";
    const link = document.createElement("a");
    link.href = url;
    // link.download = fileName;
    link.click();
  };

  // // 임시 폴더 삭제 기능
  // const handleDeleteTemp = async (url: string) => {
  //   const formData = new FormData();
  //   formData.append("temporary_directory", url);
  //   const res = await swapApi.delete("/delete_temporary", { data: formData });
  //   console.log(res);
  // };

  // useEffect(() => {
  //   return () => {
  //     // console.log("unmount");
  //     if (resultState && resultState.length === 1) {
  //       const tempDir = resultState[0]?.temporary_directory;
  //       if (tempDir) {
  //         (async () => {
  //           try {
  //             await handleDeleteTemp(tempDir);
  //             setResultState(null);
  //           } catch (error) {
  //             console.error(error);
  //           }
  //         })();
  //       }
  //     }
  //   };
  // }, []);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mb="10px">
        <Stack
          direction="row"
          alignItems="center"
          gap="5px"
          sx={{
            cursor: "pointer",
            transition: "color 0.2s ease-in-out",
            "&:hover": {
              color: colors.point,
            },
          }}
          onClick={() => {
            navigate("/swap");
          }}
        >
          <Typography fontSize={22} fontWeight={600} textTransform="uppercase">
            새로 변환하기
          </Typography>
          <Replay
            fontSize="large"
            sx={{
              color: colors.point,
            }}
          />
        </Stack>
      </Stack>
      <GreyContainer
        p="40px"
        sx={{
          gap: {
            xs: "20px",
            lg: "40px",
          },
          overflowX: "auto",
        }}
      >
        <Stack alignItems="center">
          <Typography
            fontWeight={600}
            sx={{
              fontSize: {
                xs: "24px",
                lg: "30px",
              },
            }}
          >
            변환 결과물 확인
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap="5px"
            sx={{ color: colors.variant.grey150 }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "18px",
                  lg: "24px",
                },
              }}
            >
              Click the image for more details
            </Typography>
            <TouchApp />
          </Stack>
        </Stack>
        <Stack
          width="100%"
          flexGrow={1}
          sx={{
            // flexDirection: {
            //   xs: "column",
            //   lg: "row",
            // },
            gap: "30px",
          }}
        >
          {resultState && resultState?.length > 1 ? (
            <Stack
              width="100%"
              direction="row"
              justifyContent="center"
              gap="3%"
            >
              {resultState.map((item, idx) => (
                <Stack key={idx} width="17.6%" gap="20px" alignItems="center">
                  <img
                    src={item.result_url}
                    alt={`result-${idx}`}
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "contain",
                      backgroundColor: colors.background,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setEnlargeImage({
                        open: true,
                        result_url: item.result_url,
                        large: false,
                      })
                    }
                  />
                  <IconButton
                    color="error"
                    onClick={() => {
                      handleDownload(item.result_url);
                    }}
                  >
                    <Download sx={{ color: colors.point }} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          ) : (
            <>
              <Stack
                flex={1}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  width="25%"
                  justifyContent="center"
                  alignItems="flex-end"
                  gap="15px"
                >
                  {type?.includes("image") ? (
                    <img
                      src={file ? URL.createObjectURL(file) : guideImage}
                      alt="before"
                      style={{
                        width: "100%",
                        height: "360px",
                        objectFit: "contain",
                        cursor: "pointer",
                        backgroundColor: colors.background,
                        boxShadow:
                          "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
                      }}
                      onClick={() =>
                        setEnlargeImage({
                          open: true,
                          result_url: file
                            ? URL.createObjectURL(file)
                            : guideImage,
                          large: false,
                        })
                      }
                    />
                  ) : (
                    <video
                      controls
                      src={file ? URL.createObjectURL(file) : guideImage}
                      style={{
                        width: "100%",
                        height: "360px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Typography
                    fontSize={22}
                    fontWeight={600}
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                  >
                    Target Content
                  </Typography>
                </Stack>
                <Add sx={{ fontSize: 40 }} />
                <Stack
                  width="25%"
                  justifyContent="center"
                  alignItems="flex-end"
                  gap="15px"
                >
                  <img
                    src={
                      sourceFile
                        ? URL.createObjectURL(sourceFile)
                        : sourceImagePath
                        ? sourceImagePath
                        : guideImage
                    }
                    alt="sourceFile"
                    style={{
                      width: "100%",
                      height: "360px",
                      objectFit: "contain",
                      cursor: "pointer",
                      backgroundColor: colors.background,
                      boxShadow:
                        "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
                    }}
                    onClick={() =>
                      setEnlargeImage({
                        open: true,
                        result_url: sourceFile
                          ? URL.createObjectURL(sourceFile)
                          : guideImage,
                        large: false,
                      })
                    }
                  />
                  <Typography
                    fontSize={22}
                    fontWeight={600}
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                  >
                    Source Content
                  </Typography>
                </Stack>
                <ArrowForwardIos sx={{ fontSize: 28 }} />
                <Stack
                  width="47%"
                  justifyContent="center"
                  alignItems="flex-end"
                  gap="15px"
                >
                  {type?.includes("image") ? (
                    <img
                      src={resultState ? resultState[0].result_url : guideImage}
                      alt="result"
                      style={{
                        width: "100%",
                        height: "360px",
                        objectFit: "contain",
                        cursor: "pointer",
                        backgroundColor: colors.background,
                        boxShadow:
                          "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
                      }}
                      onClick={() =>
                        setEnlargeImage({
                          open: true,
                          result_url: resultState
                            ? resultState[0].result_url
                            : guideImage,
                          large: false,
                        })
                      }
                    />
                  ) : (
                    <video
                      controls
                      src={resultState ? resultState[0].result_url : guideImage}
                      style={{
                        width: "100%",
                        height: "360px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Typography
                    fontSize={22}
                    fontWeight={600}
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                  >
                    Result Content
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                // justifyContent="flex-end"
                // alignItems="flex-end"
                // gap="20px"
              >
                <StyledButton
                  onClick={() => {
                    handleDownload(
                      resultState ? resultState[0].result_url : guideImage
                    );
                  }}
                >
                  저장
                </StyledButton>
              </Stack>
            </>
          )}
        </Stack>
        <ImageDialog
          enlargeImage={enlargeImage}
          setEnlargeImage={setEnlargeImage}
        />
      </GreyContainer>
    </>
  );
};

export default VirtualFaceComplete;
