export const colors = {
  background: "#12100f",
  white: "#fff",
  black: "#000",
  point: "#ff6410",
  basic: "#00418a",
  variant: {
    grey50: "#2e2e2e",
    grey100: "#535353",
    grey125: "#676564",
    grey150: "#acacad",
  },
};
