import React, { useEffect, useState } from "react";
import { ContentsContainer, GreyContainer, StyledButton } from "../ui/Common";
import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { colors } from "@assets/styles/colors";
import { ColorlibConnector, ColorlibStepIcon } from "./VirtualFaceStyle";
import VirtualFaceUpload from "./VirtualFaceUpload";
import VirtualFaceSource from "./VirtualFaceSource";
import VirtualFaceComplete from "./VirtualFaceComplete";
import { useNavigate, useParams } from "react-router-dom";
import { swapApi } from "@assets/api";
import { useCreateVContentMutation } from "src/lib/api";
import UploadLoading from "../ui/UploadLoading";
import awsmobile from "@assets/aws-exports";
import { Storage } from "aws-amplify";

export type resultType = {
  status: string;
  // temporary_directory?: string;
  // "the number of frame"?: number;
  result_url: string;
};

const VirtualFaceStep = () => {
  const { type } = useParams();

  const navigate = useNavigate();

  const key = `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`;
  const userId = localStorage.getItem(key);

  const [activeStep, setActiveStep] = useState(0);

  const steps = ["파일 업로드", "변환얼굴 선택", "변환 완료"];

  // 타겟 파일
  const [file, setFile] = useState<File | null>(null);

  // 타겟 파일 - 다중
  const [multipleOn, setMultipleOn] = useState(false);
  const [targetFiles, setTargetFiles] = useState<File[] | null>(null);

  // console.log(targetFiles);

  // 소스 파일
  const [sourceFile, setSourceFile] = useState<File | null>(null);

  // 특정 얼굴
  const [objectFile, setObjectFile] = useState<File | null>(null);

  // 변환 모드
  const [faceIndex, setFaceIndex] = useState<number | null>(null);

  // 결과 정보
  const [resultState, setResultState] = useState<resultType[] | null>(null);

  // 피부 소스 파일 경로
  const [sourceImagePath, setSourceImagePath] = useState("");

  // 소스 설정
  useEffect(() => {
    if (!type?.includes("image") && type !== "video") {
      navigate("/swap");
    }
  }, [type]);

  // 변환 이미지 등록
  const { mutate } = useCreateVContentMutation();

  // 로딩
  const [loading, setLoading] = useState(false);
  // const [loadingProgress, setLoadingProgress] = useState(0);

  // 특정 얼굴 찾기
  const [faceList, setFaceList] = useState<string[]>([]);

  const handleFindFace = async () => {
    if (!file) {
      alert("파일을 업로드 해주세요.");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("target_image", file);

      const res = await swapApi.post("/find_face", formData);

      const result = res.data.result;

      if (result && result.length > 0) {
        setFaceList(result);
        return true;
      }
    } catch (error) {
      console.error(error);
      alert("얼굴을 찾는 과정에서 오류가 발생했습니다. 다시 시도해 주세요.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 컨텐츠 변환
  const handleSubmit = async () => {
    const targetImage = multipleOn ? targetFiles : [file];

    if (type === "image_skin") {
      if (!sourceImagePath) {
        alert("이미지를 선택해 주세요.");
        return;
      }
    } else {
      if (!targetImage || !targetImage?.length || !sourceFile) {
        alert("파일을 업로드 해주세요.");
        return;
      }
    }

    try {
      setLoading(true);

      const formData = new FormData();

      if (type === "video") {
        formData.append("target_video", file!);
      } else {
        targetImage?.forEach((item) => {
          item && formData.append("target_image", item);
        });
        if (faceIndex !== null) {
          formData.append("index", faceIndex.toString());
        }
      }

      if (type === "image_skin") {
        formData.append("source_image", sourceImagePath);
      } else formData.append("source_image", sourceFile as File);

      if (objectFile) {
        formData.append("object_image", objectFile);
      }

      const swapRes = await swapApi.post(
        `/${
          type === "video"
            ? "synthesize_video"
            : type === "image_skin"
            ? "skin_transfer"
            : type === "image_back"
            ? "wallpaper"
            : "synthesize_image_form"
        }`,
        formData
      );

      //console.log(swapRes.data.result);

      const result = swapRes.data.result;

      if (type === "image_skin" || type === "image_back") {
        if (result && result.status === "Success") {
          const sourceUrl =
            "VContents/source-" +
            Date.now() +
            "." +
            sourceFile?.name.split(".").slice(-1);
          await Storage.put(sourceUrl, sourceFile);

          const targetUrl =
            "VContents/target-" +
            Date.now() +
            "." +
            targetImage?.[0]?.name.split(".").slice(-1);
          await Storage.put(targetUrl, targetImage?.[0]);

          await mutate({
            input: {
              userID: userId ? userId : "test@test.com",
              VFaceID: type === "image_skin" ? sourceImagePath : sourceUrl,
              fileName: targetUrl,
              storage: result.result_url,
              isBookmarked: false,
              type: "image",
            },
          });

          setResultState([result]);
          setActiveStep(2);

          console.log([result]);
        }
      } else {
        if (result && result.length > 0) {
          // const targetUrl =
          //   "VContents/target" +
          //   Date.now() +
          //   "." +
          //   file?.name.split(".").slice(-1);
          // await Storage.put(targetUrl, file);

          const sourceUrl =
            "VContents/source-" +
            Date.now() +
            "." +
            sourceFile?.name.split(".").slice(-1);
          await Storage.put(sourceUrl, sourceFile);

          // const targetImages = multipleOn ? targetFiles! : [file!];

          const dbUpload = result.map(async (item: resultType, idx: number) => {
            const targetFile = targetImage?.[idx];

            const targetUrl =
              "VContents/target-" +
              Date.now() +
              idx +
              "." +
              targetFile?.name.split(".").slice(-1);
            await Storage.put(targetUrl, targetFile);

            return await mutate({
              input: {
                userID: userId ? userId : "test@test.com",
                VFaceID: sourceUrl,
                fileName: targetUrl,
                storage: item.result_url,
                isBookmarked: false,
                type: type === "video" ? "video" : "image",
              },
            });
          });

          await Promise.all(dbUpload);

          setResultState(result);
          setActiveStep(2);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = `변환 과정에서 오류가 발생했습니다. ${
        type === "video"
          ? "영상의 화질이 높거나 길이가 길고, 변환할 인물이 많을 경우 변환이 실패할 수 있습니다."
          : "이미지의 해상도가 높거나 변환할 인물이 많을 경우 변환이 실패할 수 있습니다."
      } 다시 시도해 주세요.`;
      alert(errorMessage);
      setActiveStep(0);
      setFile(null);
      setTargetFiles(null);
      setSourceFile(null);
      setObjectFile(null);
      setMultipleOn(false);
      setFaceIndex(null);
      setFaceList([]);
      setSourceImagePath("");
    } finally {
      setLoading(false);
    }
  };

  const props = {
    type,
    file,
    setFile,
    setActiveStep,
    multipleOn,
    setMultipleOn,
    targetFiles,
    setTargetFiles,
  };
  const sourceProps = {
    type,
    file,
    setFile,
    sourceFile,
    setSourceFile,
    objectFile,
    setObjectFile,
    faceList,
    setFaceList,
    setActiveStep,
    faceIndex,
    setFaceIndex,
    multipleOn,
    handleFindFace,
    handleSubmit,
    setSourceImagePath,
    sourceImagePath,
  };
  const resultProps = {
    type,
    file,
    setFile,
    sourceFile,
    setSourceFile,
    resultState,
    setResultState,
    setSourceImagePath,
    sourceImagePath,
  };

  return (
    <ContentsContainer>
      <Stack
        // width="100%"
        justifyContent="center"
        alignItems="center"
        mb="40px"
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={
                    activeStep === idx ? colors.point : colors.variant.grey150
                  }
                  textTransform="uppercase"
                >
                  Step{idx + 1}
                </Typography>
                <Typography
                  lineHeight="16px"
                  fontWeight={500}
                  color={colors.variant.grey150}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack
        sx={{
          flexGrow: 1,
          px: {
            xs: "0px",
            lg: "150px",
          },
        }}
      >
        {activeStep === 0 ? (
          <VirtualFaceUpload {...props} />
        ) : activeStep === 1 ? (
          <VirtualFaceSource {...sourceProps} />
        ) : (
          <VirtualFaceComplete {...resultProps} />
        )}
      </Stack>
      {loading && <UploadLoading />}
    </ContentsContainer>
  );
};

export default VirtualFaceStep;
