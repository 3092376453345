import { createTheme } from "@mui/material/styles";
import { colors } from "@colors";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "NanumSquareAcr",
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          fontSize: "102px",
          // fontWeight: 700,
        },
        iconFilled: {
          color: "#ff6410",
        },
        iconHover: {
          color: "#ff6410",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "'NanumSquareAcr', sans-serif",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#373737",
          color: "#fff",
          fontFamily: "'NanumSquareAcr', sans-serif",
          fontWeight: "600 !important",
          // ".MuiMenuItem-root:hover": {
          //   backgroundColor: "#778583 !important",
          // },
          // ".Mui-selected": {
          //   backgroundColor: "#ff6410 !important",
          //   "&:hover": {
          //     background: "#E6590E !important",
          //   },
          // },
        },
      },
    },
  },
  typography: {
    fontFamily: "'Pretendard', sans-serif",
  },
  palette: {
    primary: {
      main: "#ff6410",
      dark: "#ff6410",
    },
    secondary: {
      main: "#ababab",
    },
  },
});
