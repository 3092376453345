import React, { useState } from "react";
import {
  ContentsContainer,
  GreyContainer,
  StyledButton,
  TextButton,
} from "../ui/Common";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { colors } from "@assets/styles/colors";
import {
  AccountCircleOutlined,
  Add,
  ArrowForwardIos,
  Close,
  PlayCircleOutlined,
} from "@mui/icons-material";
import {
  ModelSortDirection,
  VContent,
  useInfiniteVContentByUserQuery,
} from "src/lib/api";
import moment from "moment";
import { FixedSizeList } from "react-window";
import awsmobile from "@assets/aws-exports";
import loadingImg from "@image/imageLoading.png";
import S3ContentLoad from "../ui/S3ContentLoad";

const VContentsList = () => {
  const key = `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`;
  const userId = localStorage.getItem(key);

  const vContentData = useInfiniteVContentByUserQuery(
    "nextToken",
    {
      userID: userId ?? "test@test.com",
      sortDirection: ModelSortDirection.Desc,
      limit: 200,
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPageToken = lastPage?.VContentByUser?.nextToken;
        return nextPageToken ? lastPage?.VContentByUser : undefined;
      },
    }
  );

  const [mode, setMode] = useState("image");

  const contentList =
    vContentData?.data?.pages.flatMap((page) => page.VContentByUser?.items) ??
    [];

  const itemData = contentList?.filter((item) => item?.type === mode);

  // console.log(itemData);

  const [selectedImg, setSelectedImg] = useState<VContent | null>(null);
  const [imageOpen, setImageOpen] = useState(false);

  const handleClose = () => {
    setImageOpen(false);
    // setSelectedImg(null);
  };

  // console.log(selectedImg);

  // function generateFrameUrls(baseURL: string, frameCount: number) {
  //   const urls = [];
  //   for (let i = 0; i < frameCount; i++) {
  //     const paddedIndex = String(i).padStart(7, "0");
  //     const url = `${baseURL}/frame_${paddedIndex}.jpg`;
  //     urls.push(url);
  //   }
  //   return urls;
  // }

  // // 사용 예:
  // const baseURL =
  //   "https://fliption-web-bucket-dev.s3.ap-northeast-2.amazonaws.com/cj-test/frame-image/2024/3/19/frame-17108298188337843";
  // const frameCount = 165;
  // const urls = generateFrameUrls(baseURL, frameCount);
  // console.log(urls);

  return (
    <ContentsContainer
      alignItems="center"
      gap="40px"
      sx={{
        padding: {
          xs: "40px 50px",
          lg: "40px 200px",
        },
      }}
    >
      <Typography fontSize={30} fontWeight={700}>
        Results
      </Typography>
      <Stack width="100%" gap="10px">
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          gap="20px"
        >
          <TextButton
            selected={mode === "image"}
            onClick={() => {
              setMode("image");
            }}
            fontSize={20}
          >
            <AccountCircleOutlined sx={{ mb: "3px" }} />
            &nbsp;Image
          </TextButton>
          <TextButton
            selected={mode === "video"}
            onClick={() => {
              setMode("video");
            }}
            fontSize={20}
          >
            <PlayCircleOutlined sx={{ mb: "3px" }} />
            &nbsp;Video
          </TextButton>
        </Stack>
        <GreyContainer
          width="100%"
          px="20px"
          py="40px"
          sx={{
            height: "50vh",
            gap: {
              xs: "20px",
              lg: "40px",
            },
            overflowX: "auto",
          }}
        >
          {vContentData.isLoading ? (
            <CircularProgress color="primary" />
          ) : itemData.length > 0 ? (
            <FixedSizeList
              height={500}
              itemCount={Math.ceil(itemData.length / 3)}
              itemSize={260}
              width={1000}
            >
              {({ index, style }) => {
                // const items = itemData.slice(index * 3, index * 3 + 3);
                if (
                  index * 3 + 3 >= itemData.length &&
                  vContentData.hasNextPage &&
                  !vContentData.isFetchingNextPage
                ) {
                  // console.log("갱신");
                  vContentData.fetchNextPage();
                  return <div style={style}>Loading...</div>;
                }

                const items = itemData.slice(index * 3, index * 3 + 3);
                return (
                  <div style={{ ...style, display: "flex" }}>
                    {items.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          margin: "1%",
                          width: "31%",
                          display: "flex",
                          flex: "none",
                          alignContent: "stretch",
                          boxSizing: "border-box",
                        }}
                      >
                        {mode === "image" ? (
                          <img
                            src={item?.storage}
                            alt={item?.fileName}
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "250px",
                              background: colors.background,
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (item) {
                                setSelectedImg(item);
                              }
                              setImageOpen(true);
                            }}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null;
                              target.src = loadingImg;
                              e.currentTarget.onerror = null;
                            }}
                          />
                        ) : (
                          <video
                            src={`${item?.storage}`}
                            style={{
                              width: "100%",
                              height: "250px",
                              background: colors.background,
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (item) {
                                setSelectedImg(item);
                              }
                              setImageOpen(true);
                            }}
                            onError={(e) => {
                              const target = e.target as HTMLVideoElement;
                              target.style.backgroundImage = `url(${loadingImg})`;
                              target.style.backgroundSize = "contain";
                              target.style.backgroundRepeat = "no-repeat";
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                );
              }}
            </FixedSizeList>
          ) : (
            <Typography fontSize={24} fontWeight={500}>
              현재 변환된 컨텐츠가 없습니다.
            </Typography>
          )}
        </GreyContainer>
      </Stack>
      <Dialog
        open={imageOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: "70%",
            // flexDirection: {
            //   xs: "column",
            //   md: "row",
            // },
            flexDirection: "column",
            p: "20px",
            gap: "40px",
            background: colors.variant.grey100,
          },
        }}
      >
        {/* <Close
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={handleClose}
        /> */}
        <Stack
          flex={1}
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {/* {mode === "image" ? (
            <img
              src={selectedImg?.storage}
              alt="resultContent"
              loading="lazy"
              style={{
                width: "100%",
                height: "60vh",
                background: colors.background,
                objectFit: "contain",
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = loadingImg;
                e.currentTarget.onerror = null;
              }}
            />
          ) : (
            <video
              src={`${selectedImg?.storage}`}
              controls
              style={{
                width: "100%",
                // height: "100%",
                height: "60vh",
                background: colors.background,
                objectFit: "contain",
              }}
              onError={(e) => {
                const target = e.target as HTMLVideoElement;
                target.style.backgroundImage = `url(${loadingImg})`;
                target.style.backgroundSize = "contain";
                target.style.backgroundRepeat = "no-repeat";
              }}
            />
          )} */}
          <Stack
            width="25%"
            justifyContent="center"
            alignItems="flex-end"
            gap="15px"
          >
            <S3ContentLoad
              type={mode === "image" ? "image" : "video"}
              s3Url={selectedImg?.fileName!}
            />
            <Typography
              fontSize={22}
              fontWeight={600}
              color="#fff"
              sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Target Content
            </Typography>
          </Stack>
          <Add sx={{ fontSize: 40, color: "#fff" }} />
          <Stack
            width="25%"
            justifyContent="center"
            alignItems="flex-end"
            gap="15px"
          >
            {selectedImg?.VFaceID.includes("/asset/skin/") ? (
              <img
                src={selectedImg?.VFaceID}
                alt="before"
                style={{
                  width: "100%",
                  height: "50vh",
                  objectFit: "contain",
                  backgroundColor: colors.background,
                  boxShadow:
                    "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
                }}
              />
            ) : (
              <S3ContentLoad type="image" s3Url={selectedImg?.VFaceID!} />
            )}
            <Typography
              fontSize={22}
              fontWeight={600}
              color="#fff"
              sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Source Content
            </Typography>
          </Stack>
          <ArrowForwardIos sx={{ fontSize: 28, color: "#fff" }} />
          <Stack
            width="45%"
            justifyContent="center"
            alignItems="flex-end"
            gap="15px"
          >
            {mode === "image" ? (
              <img
                src={selectedImg?.storage}
                alt="before"
                style={{
                  width: "100%",
                  height: "50vh",
                  objectFit: "contain",
                  backgroundColor: colors.background,
                  boxShadow:
                    "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
                }}
              />
            ) : (
              <video
                controls
                src={selectedImg?.storage}
                style={{
                  width: "100%",
                  height: "50vh",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography
              fontSize={22}
              fontWeight={600}
              color="#fff"
              sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Result Content
            </Typography>
          </Stack>
        </Stack>
        <Stack flex={1} position="relative" gap="20px">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap="20px"
          >
            <StyledButton
              background={colors.variant.grey150}
              sx={{
                width: "100px",
              }}
              onClick={() => {
                setImageOpen(false);
              }}
            >
              닫기
            </StyledButton>
            <StyledButton
              sx={{
                width: "100px",
              }}
            >
              저장
            </StyledButton>
          </Stack>
        </Stack>
      </Dialog>
    </ContentsContainer>
  );
};

export default VContentsList;
