import { colors } from "@assets/styles/colors";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  ImageSelectBox,
  StepBtBox,
  VirtualSelectBt,
  VisuallyHiddenInput,
} from "./VirtualFaceStyle";
import {
  ArrowBackIos,
  ChangeCircle,
  CloudUpload,
  PeopleAltOutlined,
  PersonOutline,
} from "@mui/icons-material";
import { GreyContainer, LineButton, StyledButton } from "../ui/Common";
import guideImage from "@image/Group 17.png";
import DndBox from "../ui/DndBox";
import StepBtContainer from "../ui/StepBtContainer";
import ProgramGuideBox from "../ui/ProgramGuideBox";
import MStepBt from "../ui/MStepBt";
import { styled } from "styled-components";

const IconButton1 = styled(IconButton)<{ selected: boolean }>`
  && {
    overflow: hidden;
    padding: 0;
    outline: ${(props) => (props.selected ? "4px solid #ff6410" : "none")};
    outline-offset: -3px;
    border-radius: 8px;
    transition: border 0.3s ease-in-out;

    &:hover {
      outline: 4px solid #ff6410;
      outline-offset: -3px;
      border-radius: 8px;
    }
  }
`;

interface VirtualFaceSourceProps {
  type: string | undefined;
  setActiveStep: (step: number) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  objectFile: File | null;
  setObjectFile: (file: File | null) => void;
  sourceFile: File | null;
  setSourceFile: (file: File | null) => void;
  faceIndex: number | null;
  setFaceIndex: (index: number | null) => void;
  faceList: string[];
  setFaceList: (faceList: string[]) => void;
  multipleOn: boolean;
  handleFindFace: () => Promise<boolean | undefined>;
  handleSubmit: () => Promise<void>;
  setSourceImagePath: (path: string) => void;
  sourceImagePath: string;
}

const VirtualFaceSource = (props: VirtualFaceSourceProps) => {
  const {
    type,
    file,
    setFile,
    setActiveStep,
    objectFile,
    setObjectFile,
    sourceFile,
    setSourceFile,
    faceIndex,
    setFaceIndex,
    faceList,
    setFaceList,
    multipleOn,
    handleFindFace,
    handleSubmit,
    setSourceImagePath,
    sourceImagePath,
  } = props;

  const [swapMode, setSwapMode] = useState(0);

  const handleIconClick = (path: string) => {
    setSourceImagePath(path);
  };

  const images = [
    {
      src: "https://fliption-api-bucket.s3.ap-northeast-2.amazonaws.com/asset/skin/dark.png",
      alt: "dark skin color",
    },
    {
      src: "https://fliption-api-bucket.s3.ap-northeast-2.amazonaws.com/asset/skin/brown.png",
      alt: "brown skin color",
    },
    {
      src: "https://fliption-api-bucket.s3.ap-northeast-2.amazonaws.com/asset/skin/medium.png",
      alt: "medium skin color",
    },
    {
      src: "https://fliption-api-bucket.s3.ap-northeast-2.amazonaws.com/asset/skin/fair.png",
      alt: "fair skin color",
    },
    {
      src: "https://fliption-api-bucket.s3.ap-northeast-2.amazonaws.com/asset/skin/pale.png",
      alt: "pale skin color",
    },
  ];

  useLayoutEffect(() => {
    if (type === "video") {
      setSwapMode(1);
    }
  }, [type]);

  const handlePrevClick = () => {
    setActiveStep(0);
    setSourceFile(null);
    setObjectFile(null);
    setFaceIndex(null);
    setFaceList([]);
    setSourceImagePath("");
  };

  const selectGuideProps = {
    guide: type?.includes("image")
      ? file
        ? URL.createObjectURL(file)
        : guideImage
      : guideImage,
    step: 2,
    title: "대상 얼굴 선택하기",
    description: type?.includes("image") ? (
      <>
        변환 하고자 하는 <b style={{ fontWeight: 800 }}>대상</b> 얼굴을 선택해
        주세요.
        <br />
        선택하신 <b style={{ fontWeight: 800 }}>대상</b> 얼굴이 변환됩니다.
      </>
    ) : (
      <>
        변환 대상의 이미지를 업로드해 주세요.
        <br />
        영상 속 대상의 얼굴을 캡쳐하여 업로드하는 것을 추천드립니다.
      </>
    ),
  };

  const sourceGuideProps = {
    guide: guideImage,
    step: 2,
    title:
      type === "image_skin"
        ? "피부색 선택하기"
        : type === "image_back"
        ? "배경 선택하기"
        : "가상 얼굴 선택하기",
    description: (
      <>
        {type === "image_skin" && "변환 하고자 하는 피부색을 선택해 주세요."}
        {type === "image_back" && "변환 하고자 하는 배경을 업로드해 주세요."}
        {type === "image" && "변환 하고자 하는 가상 얼굴을 업로드해 주세요."}
        <br />
        선택하신 가상{" "}
        {type === "image_back"
          ? "배경으"
          : type === "image_skin"
          ? "피부색으"
          : "얼굴"}
        로 {type?.includes("image") ? "사진" : "영상"}이 변환됩니다.
      </>
    ),
  };

  return (
    <Stack
      sx={{
        gap: {
          xs: "20px",
          md: "40px",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Stack gap="40px" flex={1}>
        {swapMode !== 0 ? (
          <ProgramGuideBox
            {...(swapMode === 1 ? selectGuideProps : sourceGuideProps)}
          />
        ) : null}
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <MStepBt role="prev" handleClick={handlePrevClick} />
          <MStepBt
            role="next"
            disabled={!sourceFile}
            handleClick={() => {
              if (sourceFile) {
                // setActiveStep(2);
                handleSubmit();
              }
            }}
          />
        </Stack> */}
        <Stack gap="20px">
          {swapMode === 0 && !type?.includes("image_") ? (
            <Stack
              flex={1}
              gap="40px"
              sx={{
                background: colors.variant.grey50,
                borderRadius: "100px",
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                p: {
                  xs: "40px",
                  lg: "40px 100px",
                },
              }}
            >
              {type?.includes("image") && (
                <VirtualSelectBt
                  height="60vh"
                  onClick={() => {
                    setSwapMode(2);
                    setFaceIndex(null);
                  }}
                >
                  <Stack alignItems="center" gap="20px">
                    <Typography
                      fontWeight={700}
                      sx={{
                        fontSize: {
                          xs: "24px",
                          md: "30px",
                        },
                      }}
                    >
                      단일 얼굴 변환
                    </Typography>
                    <PersonOutline
                      sx={{
                        fontSize: {
                          xs: "80px",
                          lg: "125px",
                        },
                      }}
                    />
                    <Typography
                      fontWeight={500}
                      textAlign="center"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          md: "18px",
                        },
                      }}
                    >
                      컨텐츠 내에서
                      <br />
                      영역이 가장 큰 얼굴이 변환됩니다.
                    </Typography>
                  </Stack>
                </VirtualSelectBt>
              )}
              <VirtualSelectBt
                height="60vh"
                onClick={() => {
                  setSwapMode(2);
                  setFaceIndex(-1);
                }}
              >
                <Stack alignItems="center" gap="20px">
                  <Typography
                    fontWeight={700}
                    sx={{
                      fontSize: {
                        xs: "24px",
                        md: "30px",
                      },
                    }}
                  >
                    모든 얼굴 변환
                  </Typography>
                  <PeopleAltOutlined
                    sx={{
                      fontSize: {
                        xs: "80px",
                        lg: "125px",
                      },
                    }}
                  />
                  <Typography
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                      fontSize: {
                        xs: "14px",
                        md: "18px",
                      },
                    }}
                  >
                    컨텐츠에서 인식되는
                    <br />
                    모든 얼굴이 변환됩니다.
                  </Typography>
                </Stack>
              </VirtualSelectBt>
              {!multipleOn && (
                <VirtualSelectBt
                  height="60vh"
                  onClick={async () => {
                    if (type?.includes("image")) {
                      const result = await handleFindFace();
                      if (result) {
                        setSwapMode(1);
                      }
                    } else {
                      setSwapMode(1);
                    }
                    // setSwapMode(1);
                  }}
                >
                  <Stack alignItems="center" gap="20px">
                    <Typography
                      fontWeight={700}
                      sx={{
                        fontSize: {
                          xs: "24px",
                          md: "30px",
                        },
                      }}
                    >
                      특정 얼굴 변환
                    </Typography>
                    <PersonOutline
                      sx={{
                        fontSize: {
                          xs: "80px",
                          lg: "125px",
                        },
                      }}
                    />
                    <Typography
                      fontWeight={500}
                      textAlign="center"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          md: "18px",
                        },
                      }}
                    >
                      컨텐츠에 여러명의 인물이 나온다면
                      <br />
                      사용을 추천드립니다.
                    </Typography>
                  </Stack>
                </VirtualSelectBt>
              )}
            </Stack>
          ) : swapMode === 1 && type?.includes("image") ? (
            <GreyContainer>
              <Typography
                fontWeight={600}
                sx={{
                  fontSize: {
                    xs: "24px",
                    lg: "30px",
                  },
                }}
              >
                변환할 얼굴을 선택해 주세요.
              </Typography>
              <Stack
                gap="40px"
                width="100%"
                flexGrow={1}
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Stack justifyContent="center" alignItems="center" gap="20px">
                  <img
                    src={file ? URL.createObjectURL(file) : guideImage}
                    alt="before"
                    style={{
                      width: "100%",
                      maxWidth: "360px",
                      maxHeight: "360px",
                      objectFit: "contain",
                    }}
                  />
                  <Typography fontSize={20} fontWeight={600}>
                    변환 전
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <ImageSelectBox>
                    {faceList.map((item, idx) => (
                      <img
                        key={item}
                        src={item}
                        alt={`fixImg${idx + 1}`}
                        className={faceIndex === idx ? "selected" : ""}
                        onClick={() => {
                          if (faceIndex === idx) {
                            setFaceIndex(null);
                          } else {
                            setFaceIndex(idx);
                          }
                        }}
                      />
                    ))}
                  </ImageSelectBox>
                  <Stack direction="row" justifyContent="flex-end" gap="20px">
                    <StyledButton
                      background={colors.variant.grey100}
                      onClick={() => {
                        // setFixState(false);
                        // setSelectedImg("");
                        setSwapMode(0);
                        setFaceIndex(null);
                        setFaceList([]);
                      }}
                    >
                      이전
                    </StyledButton>
                    <StyledButton
                      onClick={async () => {
                        if (faceIndex === null)
                          return alert("이미지를 선택해 주세요.");
                        setSwapMode(2);
                      }}
                    >
                      다음
                    </StyledButton>
                  </Stack>
                </Stack>
              </Stack>
            </GreyContainer>
          ) : swapMode === 1 ? (
            <DndBox file={objectFile} setFile={setObjectFile}>
              {objectFile ? (
                <>
                  <img
                    src={URL.createObjectURL(objectFile)}
                    alt="upload"
                    height="350px"
                    style={{
                      width: "100%",
                      maxWidth: "650px",
                      objectFit: "contain",
                    }}
                  />
                  <Stack direction="row" gap="20px">
                    <Button
                      component="label"
                      variant="contained"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "20px",
                        },
                        fontWeight: 800,
                        color: "#fff",
                        background: colors.variant.grey150 + " !important",
                        borderRadius: "10px",
                        boxShadow: "none",
                        textTransform: "none",
                      }}
                    >
                      Change File
                      <VisuallyHiddenInput
                        type="file"
                        accept={"image/jpeg, image/jpg, image/png"}
                        onChange={(e) => {
                          setObjectFile(
                            e.target.files ? e.target.files[0] : null
                          );
                        }}
                      />
                    </Button>
                    <StyledButton
                      background={colors.point}
                      onClick={() => {
                        setSwapMode(2);
                      }}
                    >
                      다음
                    </StyledButton>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    Object File Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고자 하는 대상 얼굴 이미지를 업로드 해주세요.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    File Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setObjectFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              )}
            </DndBox>
          ) : (
            <DndBox file={sourceFile} setFile={setSourceFile}>
              {sourceFile ? (
                <>
                  <img
                    src={URL.createObjectURL(sourceFile)}
                    alt="upload"
                    height="350px"
                    style={{
                      width: "100%",
                      maxWidth: "650px",
                      objectFit: "contain",
                    }}
                  />
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Change File
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setSourceFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              ) : type === "image_skin" ? (
                <>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="50px"
                    flexWrap="wrap"
                  >
                    {images.map((image) => (
                      <IconButton1
                        key={image.src}
                        selected={sourceImagePath === image.src}
                        onClick={() => handleIconClick(image.src)}
                      >
                        <img src={image.src} alt={image.alt} />
                      </IconButton1>
                    ))}
                  </Stack>
                  {/* <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      minWidth: 200,
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                      marginLeft: "auto",
                    }}
                  >
                    선택
                  </Button> */}
                </>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    Source File Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고자 하는 가상 얼굴을 업로드 해주세요.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    File Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setSourceFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              )}
            </DndBox>
          )}
          <Stack direction="row" justifyContent="flex-end" gap="25px">
            <LineButton
              background={colors.variant.grey125}
              startIcon={<ArrowBackIos />}
              onClick={handlePrevClick}
            >
              Prev
            </LineButton>
            <LineButton
              endIcon={<ChangeCircle />}
              disabled={!sourceFile && !sourceImagePath}
              onClick={() => {
                if (sourceFile || sourceImagePath) {
                  // setActiveStep(2);
                  handleSubmit();
                }
              }}
              sx={{
                // opacity: swapMode === 0 ? 0 : 1,
                display:
                  swapMode === 0 && !type?.includes("image_") ? "none" : "flex",
              }}
            >
              Swap
            </LineButton>
          </Stack>
        </Stack>
      </Stack>
      {/* <Stack
        width="75px"
        justifyContent="center"
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <StepBtBox
          role="next"
          disabled={!sourceFile}
          onClick={() => {
            if (sourceFile) {
              // setActiveStep(2);
              handleSubmit();
            }
          }}
        >
          <div className="stepBt">
            <div className="stepNextArrow" />
          </div>
          <Stack alignItems="center" gap="2px">
            <ChangeCircle
              sx={{
                fontSize: "28px",
                color: sourceFile ? colors.point : colors.variant.grey100,
              }}
            />
            <Typography
              fontWeight={800}
              textTransform="uppercase"
              sx={{
                fontSize: {
                  xs: "12px",
                  md: "16px",
                },
              }}
            >
              Swap
            </Typography>
          </Stack>
        </StepBtBox>
      </Stack> */}
    </Stack>
  );
};

export default VirtualFaceSource;
