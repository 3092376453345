import { colors } from "@assets/styles/colors";
import { Check, Face, Folder, HowToReg, Portrait } from "@mui/icons-material";
import {
  Stack,
  StepConnector,
  StepIconProps,
  Typography,
  styled as muiStyled,
  stepConnectorClasses,
} from "@mui/material";
import styled from "styled-components";

export const VirtualSelectBt = styled(Stack)<{ selected?: boolean }>`
  flex: 1;
  min-width: 225px;
  /* height: 60vh; */
  justify-content: center;
  align-items: center;
  background: ${({ selected }) =>
    selected ? colors.point : colors.variant.grey100};
  border-radius: 25px;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${colors.point};
    opacity: 0.9;
  }
  @media (max-width: 1400px) {
    min-width: 20%;
  }
  @media (max-width: 900px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

// 스텝
export const ColorlibConnector = muiStyled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    backgroundColor: colors.variant.grey50,
    borderRadius: 1,
  },
}));
const ColorlibStepIconRoot = muiStyled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: colors.variant.grey100,
  zIndex: 1,
  color: colors.variant.grey150,
  width: 27,
  height: 27,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: colors.point,
    color: "#fff",
  }),
}));
export const ColorlibStepIcon: React.FC<StepIconProps> = (props) => {
  const { active, completed, className } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <Folder sx={{ fontSize: "16px" }} />,
    2: <Portrait sx={{ fontSize: "16px" }} />,
    3: <Face sx={{ fontSize: "16px" }} />,
    4: <HowToReg sx={{ fontSize: "16px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Check sx={{ fontSize: "16px" }} />
      ) : (
        icons[String(props.icon)]
      )}
    </ColorlibStepIconRoot>
  );
};

// 전환 버튼
export const StepBtBox = styled.div<{
  role: "prev" | "next";
  disabled?: boolean;
}>`
  display: inline-flex;
  flex-direction: ${({ role }) => (role === "prev" ? "row" : "row-reverse")};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  .stepBt {
    width: 25px;
    height: 80px;
    background: ${colors.variant.grey50};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stepPrevArrow {
    width: 0;
    height: 0;
    border-bottom: 14px solid transparent;
    border-top: 14px solid transparent;
    border-left: 0;
    border-right: 12px solid
      ${({ disabled }) => (disabled ? colors.variant.grey100 : colors.point)};
  }
  .stepNextArrow {
    width: 0;
    height: 0;
    border-bottom: 14px solid transparent;
    border-top: 14px solid transparent;
    border-left: 12px solid
      ${({ disabled }) => (disabled ? colors.variant.grey100 : colors.point)};
    border-right: 0;
  }
`;

// 가이드
export const GuideStep = styled(Typography)`
  display: inline-block;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 1px 20px;
  border: 1px solid ${colors.variant.grey150};
  border-radius: 10px;
  color: ${colors.variant.grey150};
`;
// 파일 업로드
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
// 이미지 선택 박스
export const ImageSelectBox = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 15px;
  /* max-height: 400px; */
  overflow-y: scroll;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    width: 100%;
    height: 200px;
    /* height: 100%; */
    object-fit: contain;
    background-color: ${colors.background};
    border: 5px solid ${colors.variant.grey50};
    border-radius: 8px;
    transition: border 0.3s ease-in-out;
    cursor: pointer;
    &.selected {
      border: 5px solid ${colors.point};
    }
  }
`;
// 프레임 선택 박스
export const FrameSelecBox = styled.div`
  width: 100%;
  height: 100%;
  > div {
    display: flex;
    width: 100%;
    gap: 40px;
    padding-bottom: 20px;
    overflow-x: scroll;
    & > div {
      flex-shrink: 0;
      cursor: pointer;
      img {
        width: 100%;
        max-height: 360px;
        object-fit: contain;
        border: 5px solid transparent;
        border-radius: 8px;
        transition: border 0.3s ease-in-out;
        &.selected {
          border: 5px solid ${colors.point};
        }
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.variant.grey100};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: ${colors.point};
    }
  }
`;
