import { LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "@image/Fliption_logo_1.png";
import { ScaleLoader } from "react-spinners";
import { colors } from "@assets/styles/colors";

const UploadLoading = () => {
  return (
    <Stack
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        gap: "40px",
        zIndex: 1400,
        backgroundColor: "#00000095",
      }}
    >
      <img
        src={Logo}
        width="175px"
        alt="Fliption"
        style={{
          objectFit: "contain",
          zIndex: 1300,
        }}
      />
      <Stack gap="40px" width="40%" alignItems="center">
        {/* <LinearProgress
          // value={progress}
          // variant="determinate"
          sx={{ height: "10px", borderRadius: "8px" }}
        /> */}
        <ScaleLoader width={8} color={colors.point} />
        <Stack direction="row" justifyContent="center">
          <Typography
            fontSize={25}
            fontWeight={600}
            color="#ced4da"
            sx={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            Please wait...
          </Typography>
          {/* <Typography fontSize={20} fontWeight={600}>
            {progress} %
          </Typography> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UploadLoading;
