import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { colors } from "@colors";
import {
  PersonOutline,
  Visibility,
  VisibilityOff,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { StyledInput } from "src/feature/ui/Common";

interface SignInBoxProps {
  id: string;
  setId: (id: string) => void;
  setPage: (page: number) => void;
}

const SignInBox = (props: SignInBoxProps) => {
  const { id, setId, setPage } = props;

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const signIn = async (email: string, password: string) => {
    try {
      const user = await Auth.signIn(email, password);
      Auth.currentAuthenticatedUser();
      // console.log("User signed in:", user);
    } catch (error: any) {
      console.log("error signing in", error);
      console.log(error.code);
      if (error.code === "UserNotConfirmedException") {
        alert("유저 인증이 필요합니다. 다시 회원가입을 진행해 주세요.");
        return;
      }
      alert("로그인에 실패했습니다. 다시 시도해 주세요.");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      signIn(id, password);
    }
  };

  return (
    <>
      <Typography variant="h4">LOG IN</Typography>
      <FormControl fullWidth sx={{ mb: "24px" }}>
        <FormLabel
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: colors.variant.grey150,
          }}
        >
          <PersonOutline
            sx={{
              fontSize: 20,
            }}
          />
          <Typography fontSize={16} fontWeight={500}>
            USER ID
          </Typography>
        </FormLabel>
        <StyledInput
          required
          value={id}
          onChange={(e) => setId(e.target.value)}
          placeholder="Please enter an ID."
        />
        {/* {errors.id && (
      <FormHelperText error>
        유효한 이메일 주소를 입력해주세요.
      </FormHelperText>
    )} */}
      </FormControl>
      <FormControl fullWidth sx={{ mb: "54px" }}>
        <FormLabel
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: colors.variant.grey150,
          }}
        >
          <VpnKeyOutlined
            sx={{
              fontSize: 20,
            }}
          />
          <Typography fontSize={16} fontWeight={500}>
            Password
          </Typography>
        </FormLabel>
        <StyledInput
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Please enter a password."
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  color: colors.variant.grey150,
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Stack width="100%" gap="8px">
        <Button
          disabled={!id || !password}
          variant="contained"
          sx={{
            height: "45px",
            color: "#fff !important",
            bgcolor: colors.background,
            ":hover": {
              bgcolor: colors.background + "E6",
            },
            borderRadius: "8px",
            fontSize: "16px",
            textTransform: "uppercase",
            boxShadow: "none",
          }}
          onClick={() => signIn(id, password)}
        >
          Log In
        </Button>
        {/* <Button
          sx={{
            color: "#fff",
            textTransform: "uppercase",
          }}
          onClick={() => setPage(1)}
        >
          Register
        </Button> */}
      </Stack>
    </>
  );
};

export default SignInBox;
