import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import NanumSquareOTF from "@font/NanumSquareOTF.otf";
import NanumSquareOTF_acB from "@font/NanumSquareOTF_acB.otf";
import PretendardLight from "@font/Pretendard-Light.woff";
import PretendardRegular from "@font/Pretendard-Regular.woff";
import PretendardMedium from "@font/Pretendard-Medium.woff";
import PretendardSemiBold from "@font/Pretendard-SemiBold.woff";
import PretendardBold from "@font/Pretendard-Bold.woff";
import PretendardExtraBold from "@font/Pretendard-ExtraBold.woff";

const GlobalStyle = createGlobalStyle`
    ${reset}

    @font-face {
  font-family: "NanumSquareAcr";
  src: local(${NanumSquareOTF}) format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "NanumSquareAcr";
  src: local(${NanumSquareOTF_acB}) format("opentype");
  font-weight: 800;
  font-display: swap;
}
  
  @font-face {
    font-family: "Pretendard";
    src: local(${PretendardLight}) format("woff");
    font-weight: 300;
    unicode-range: U+AC00-D7A3, U+0030-0039;
  }

  @font-face {
  font-family: "Pretendard";
  src: local(${PretendardRegular}) format("woff");
  font-weight: 400;
  unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: "Pretendard";
    src: local(${PretendardMedium}) format("woff");
    font-weight: 500;
    unicode-range: U+AC00-D7A3, U+0030-0039;
  }

  @font-face {
    font-family: "Pretendard";
    src: local(${PretendardSemiBold}) format("woff");
    font-weight: 600;
    unicode-range: U+AC00-D7A3, U+0030-0039;
  }

  @font-face {
    font-family: "Pretendard";
    src: local(${PretendardBold}) format("woff");
    font-weight: 700;
    unicode-range: U+AC00-D7A3, U+0030-0039;
  }

  @font-face {
    font-family: "Pretendard";
    src: local(${PretendardExtraBold}) format("woff");
    font-weight: 800;
    unicode-range: U+AC00-D7A3, U+0030-0039;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #D9D9D9;
    }
  }

  html {
    font-size: 14px;
  }

  body {
    font-family: "Pretendard", sans-serif;
    font-size: 14px;
    min-width: 280px;
    background: #1a1a1a;
    color: #fff;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    border: none;
    outline: none;
    border-radius: 4px;
  }

  input {
    display: flex;
    outline: none;
    padding-left: 10px;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #353538;
  }

  svg {
    vertical-align: middle;
  }

  .mount {
    animation: 0.3s ease-in-out hoverEffect;
  }

  @keyframes hoverEffect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default GlobalStyle;
