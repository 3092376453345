import { Dialog, DialogContent, Stack } from "@mui/material";
import React from "react";
import { StyledButton } from "./Common";

type ImageDialogType = {
  enlargeImage: {
    open: boolean;
    result_url: string;
    large: boolean;
  };
  setEnlargeImage: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      result_url: string;
      large: boolean;
    }>
  >;
};

function ImageDialog(props: ImageDialogType) {
  const { enlargeImage, setEnlargeImage } = props;
  return (
    <Dialog
      open={enlargeImage.open}
      onClose={() =>
        setEnlargeImage({ open: false, result_url: "", large: false })
      }
      PaperProps={{
        sx: {
          maxWidth: "70%",
          flexDirection: "column",
          p: "20px",
          gap: "20px",
          background: "#535353",
        },
      }}
    >
      {enlargeImage.open && (
        <>
          <Stack flex={1}>
            <img
              src={enlargeImage.result_url}
              alt="enlargeImage"
              loading="lazy"
              style={{
                width: "100%",
                height: "60vh",
                background: "#00418a",
                objectFit: "contain",
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap="20px"
          >
            <StyledButton
              background="#acacad"
              sx={{
                width: "100px",
              }}
              onClick={() =>
                setEnlargeImage({ open: false, result_url: "", large: false })
              }
            >
              닫기
            </StyledButton>
          </Stack>
        </>
      )}
    </Dialog>
  );
}

export default ImageDialog;
