import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { VisuallyHiddenInput } from "./VirtualFaceStyle";
import { colors } from "@assets/styles/colors";
import {
  ArrowBackIos,
  ArrowForwardIos,
  BurstMode,
  CloudUpload,
  Delete,
  Image,
} from "@mui/icons-material";
import DndBox from "../ui/DndBox";
import { useNavigate } from "react-router-dom";
import StepBtContainer from "../ui/StepBtContainer";
import MStepBt from "../ui/MStepBt";
import ProgramGuideBox from "../ui/ProgramGuideBox";
import {
  GreyContainer,
  LineButton,
  StyledButton,
  TextButton,
} from "../ui/Common";

interface VirtualFaceUploadProps {
  type: string | undefined;
  file: File | null;
  setFile: (file: File | null) => void;
  setActiveStep: (step: number) => void;
  multipleOn: boolean;
  setMultipleOn: (value: boolean) => void;
  targetFiles: File[] | null;
  setTargetFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
}

const VirtualFaceUpload = (props: VirtualFaceUploadProps) => {
  const {
    type,
    file,
    setFile,
    setActiveStep,
    multipleOn,
    setMultipleOn,
    targetFiles,
    setTargetFiles,
  } = props;

  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleNextStep = () => {
    const filesCheck = multipleOn ? targetFiles : file ? [file] : null;

    if (filesCheck && filesCheck.length > 0) {
      handleNext();
    } else {
      alert("파일을 업로드 해주세요.");
    }
  };

  const guideBoxProps = {
    step: 1,
    title: "파일 업로드",
    description: (
      <>
        변환 하고자 하는 {type?.includes("image") ? "사진 파일" : "영상 파일"}을
        업로드 해 주세요.
        <br />
        합성이 잘되는 파일의 구체적인 부분이 궁금하다면 아래{" "}
        <b style={{ fontWeight: 800 }}>[자세히보기]</b> 버튼을 눌러 참고하세요.
      </>
    ),
    tooltip: `${
      type?.includes("image") ? "사진" : "영상"
    }의 화질과 조명이 잘 나와 있는지 확인해 주세요. 얼굴이 화면에 잘 보이도록 사진을 선택하는 것이 중요합니다. 또한, 얼굴이 흐릿하거나 가려져 있지 않도록 주의하세요. ${
      type === "video"
        ? "영상의 경우 화질이 높거나 길이가 길고, 변환할 인물이 많을 경우 변환이 실패할 수 있습니다."
        : ""
    }`,
  };

  return (
    <Stack
      sx={{
        gap: {
          xs: "20px",
          md: "40px",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Stack gap="40px" flex={1}>
        <ProgramGuideBox {...guideBoxProps} />
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          // sx={{
          //   display: {
          //     xs: "flex",
          //     md: "none",
          //   },
          // }}
        >
          <MStepBt
            role="prev"
            handleClick={() => {
              navigate("/swap");
            }}
          />
          <MStepBt
            role="next"
            disabled={multipleOn ? targetFiles?.length === 0 : !file}
            handleClick={handleNextStep}
          />
        </Stack> */}
        <Stack gap="20px">
          {type === "image" && (
            <Stack
              width="100%"
              direction="row"
              justifyContent="flex-end"
              gap="20px"
            >
              <TextButton
                selected={!multipleOn}
                onClick={() => {
                  if (multipleOn) {
                    setFile(null);
                    setTargetFiles(null);
                  }
                  setMultipleOn(false);
                }}
                fontSize={20}
              >
                <Image sx={{ mb: "3px" }} />
                &nbsp;단일 컨텐츠 변환
              </TextButton>
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    최대 5개의 사진을 동시에 변환할 수 있습니다.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TextButton
                  selected={multipleOn}
                  onClick={() => {
                    if (!multipleOn) {
                      setFile(null);
                      setTargetFiles(null);
                    }
                    setMultipleOn(true);
                  }}
                  fontSize={20}
                >
                  <BurstMode sx={{ mb: "3px" }} />
                  &nbsp;다중 컨텐츠 변환
                </TextButton>
              </Tooltip>
            </Stack>
          )}
          {multipleOn ? (
            <GreyContainer>
              {targetFiles && targetFiles.length > 0 ? (
                <Stack width="100%" gap="40px" alignItems="center">
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="center"
                    gap="3%"
                  >
                    {targetFiles.map((item, idx) => (
                      <Stack
                        key={idx}
                        width="17.6%"
                        gap="20px"
                        alignItems="center"
                      >
                        <img
                          src={URL.createObjectURL(item)}
                          alt={item.name}
                          style={{
                            width: "100%",
                            height: "350px",
                            objectFit: "contain",
                            backgroundColor: colors.background,
                          }}
                        />
                        <IconButton
                          color="warning"
                          onClick={() => {
                            setTargetFiles((prevFiles) =>
                              prevFiles
                                ? prevFiles.filter(
                                    (file, index) => index !== idx
                                  )
                                : null
                            );
                          }}
                        >
                          <Delete sx={{ color: "#fff" }} />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Files Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={
                        type?.includes("image")
                          ? "image/jpeg, image/jpg, image/png"
                          : "video/mp4, video/avi, video/mov, video/wmv, video/flv, video/mkv"
                      }
                      multiple
                      onChange={(e) => {
                        if (e.target.files) {
                          const newFiles = Array.from(e.target.files);
                          if (targetFiles.length + newFiles.length > 5) {
                            alert("이미지는 최대 5개까지 업로드 가능합니다.");
                          } else {
                            setTargetFiles((prevFiles) => [
                              ...(prevFiles || []),
                              ...newFiles,
                            ]);
                          }
                          // Reset the file input value
                          if (e.target instanceof HTMLInputElement) {
                            e.target.value = "";
                          }
                        }
                      }}
                    />
                  </Button>
                </Stack>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    Files Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고 싶은 컨텐츠들을 업로드 해주세요.
                      <br />
                      최대 5개까지 업로드 가능합니다.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Files Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={
                        type?.includes("image")
                          ? "image/jpeg, image/jpg, image/png"
                          : "video/mp4, video/avi, video/mov, video/wmv, video/flv, video/mkv"
                      }
                      multiple
                      onChange={(e) => {
                        if (e.target.files) {
                          const newFiles = Array.from(e.target.files);
                          if (newFiles.length > 5) {
                            alert("이미지는 최대 5개까지 업로드 가능합니다.");
                          } else {
                            setTargetFiles(newFiles);
                          }
                        }
                        // Reset the file input value
                        if (e.target instanceof HTMLInputElement) {
                          e.target.value = "";
                        }
                      }}
                    />
                  </Button>
                </>
              )}
            </GreyContainer>
          ) : (
            <DndBox file={file} setFile={setFile}>
              {file ? (
                <>
                  {type?.includes("image") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="upload"
                      height="350px"
                      style={{
                        width: "100%",
                        maxWidth: "650px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      controls
                      height="350px"
                      style={{
                        width: "100%",
                        maxWidth: "650px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Change File
                    <VisuallyHiddenInput
                      type="file"
                      accept={
                        type?.includes("image")
                          ? "image/jpeg, image/jpg, image/png"
                          : "video/mp4, video/avi, video/mov, video/wmv, video/flv, video/mkv"
                      }
                      onChange={(e) => {
                        setFile(e.target.files ? e.target.files[0] : null);
                      }}
                    />
                  </Button>
                </>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    File Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고 싶은 컨텐츠를 업로드 해주세요.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    File Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={
                        type?.includes("image")
                          ? "image/jpeg, image/jpg, image/png"
                          : "video/mp4, video/avi, video/mov, video/wmv, video/flv, video/mkv"
                      }
                      onChange={(e) => {
                        setFile(e.target.files ? e.target.files[0] : null);
                      }}
                    />
                  </Button>
                </>
              )}
            </DndBox>
          )}
          <Stack direction="row" justifyContent="flex-end" gap="25px">
            <LineButton
              background={colors.variant.grey125}
              startIcon={<ArrowBackIos />}
              onClick={() => {
                navigate("/swap");
              }}
            >
              Prev
            </LineButton>
            <LineButton
              endIcon={<ArrowForwardIos />}
              disabled={multipleOn ? targetFiles?.length === 0 : !file}
              onClick={handleNextStep}
            >
              Next
            </LineButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VirtualFaceUpload;
