import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import SignIn from "@pages/auth/SignIn";
import Header from "@pages/Header";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loading from "./feature/ui/Loading";
import { Stack } from "@mui/material";
import { RootContainer } from "./feature/ui/Common";
import { Route, Routes } from "react-router-dom";
import VirtualFaceSelect from "./feature/virtualFace/VirtualFaceSelect";
import VirtualFaceStep from "./feature/virtualFace/VirtualFaceStep";
import VContentsList from "./feature/contents/VContentsList";

function App() {
  // 로그인 상태 확인
  const { authStatus } = useAuthenticator();

  return (
    <RootContainer>
      {authStatus === "authenticated" ? (
        <>
          <Header />
          <Routes>
            <Route path="swap">
              <Route index element={<VirtualFaceSelect />} />
              <Route path=":type" element={<VirtualFaceStep />} />
            </Route>
            <Route path="/contents" element={<VContentsList />} />
            <Route path="/" element={<VirtualFaceSelect />} />
            <Route path="/*" element={<VirtualFaceSelect />} />
          </Routes>
        </>
      ) : authStatus === "configuring" ? (
        <Loading />
      ) : (
        <SignIn />
      )}
    </RootContainer>
  );
}
export default App;
