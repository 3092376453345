import axios from "axios";

export const swapApi = axios.create({
  baseURL: `${process.env.REACT_APP_SWAP_API}`,
});

swapApi.interceptors.request.use(
  async (config) => {
    config.headers["x-api-key"] = process.env.REACT_APP_SWAP_API_KEY;
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
