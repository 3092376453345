import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { theme } from "@theme";
import { Amplify } from "aws-amplify";
import awsmobile from "@assets/aws-exports";
import GlobalStyle from "@assets/styles/GlobalStyle";
import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "styled-components";
import { theme as styledTheme } from "./styles/StyledTheme";

Amplify.configure(awsmobile);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
    },
    mutations: {
      networkMode: "always",
    },
  },
});

root.render(
  <Authenticator.Provider>
    <GlobalStyle />
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={styledTheme}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  </Authenticator.Provider>
);

reportWebVitals();
