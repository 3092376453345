import { colors } from "@assets/styles/colors";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { ContentsContainer } from "../ui/Common";
import { VirtualSelectBt } from "./VirtualFaceStyle";
import { useNavigate } from "react-router-dom";
import {
  InsertPhotoOutlined,
  PhotoSizeSelectActualOutlined,
  SmartDisplay,
} from "@mui/icons-material";
import Ico01 from "@image/ico_image_change.png";
import Ico02 from "@image/ico_video_change.png";
import Ico03 from "@image/ico_skin_change.png";
import Ico04 from "@image/ico_background_change.png";

const VirtualFaceSelect = () => {
  const navigate = useNavigate();
  return (
    <ContentsContainer justifyContent="center" alignItems="center">
      <Stack
        gap="36px"
        width="100%"
        sx={{
          background: colors.variant.grey50,
          borderRadius: "100px",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          p: {
            xs: "40px",
            lg: "40px 100px",
          },
        }}
      >
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/image");
          }}
        >
          <Stack alignItems="center" gap="20px" py="20px">
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              사진 변환
            </Typography>
            <img
              src={Ico01}
              alt="image change icon"
              style={{ height: "150px" }}
            />
          </Stack>
        </VirtualSelectBt>
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/video");
          }}
        >
          <Stack alignItems="center" gap="20px" py="20px">
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              영상 변환
            </Typography>
            <img
              src={Ico02}
              alt="image change icon"
              style={{ height: "150px" }}
            />
          </Stack>
        </VirtualSelectBt>
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/image_skin");
          }}
        >
          <Stack alignItems="center" gap="20px" pt="10px" pb="20px">
            <Typography
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              피부색 변경
            </Typography>
            <img
              src={Ico03}
              alt="image change icon"
              style={{ height: "120px", marginTop: "20px" }}
            />
          </Stack>
        </VirtualSelectBt>
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/image_back");
          }}
        >
          <Stack alignItems="center" gap="20px" py="20px">
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              배경 변경
            </Typography>
            <img
              src={Ico04}
              alt="image change icon"
              style={{ height: "150px" }}
            />
          </Stack>
        </VirtualSelectBt>
      </Stack>
    </ContentsContainer>
  );
};

export default VirtualFaceSelect;
