import React, { useState, ReactNode } from "react";
import { GreyContainer } from "./Common";

interface DndBoxProps {
  file: File | null;
  setFile: (file: File | null) => void;
  children: ReactNode;
  handleNext?: () => void;
  type?: string;
}

const DndBox = (props: DndBoxProps) => {
  const { file, setFile, children, handleNext, type } = props;

  const [isDragging, setIsDragging] = useState(false);

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFile = e.dataTransfer.files[0];
    const fileExtension = droppedFile.name.split(".").pop()?.toLowerCase();

    if (!fileExtension) {
      alert("파일 확장자를 확인할 수 없습니다.");
      setIsDragging(false);
      return;
    }

    const validExtensions =
      type === "video"
        ? ["mp4", "avi", "mov", "wmv", "flv", "mkv"]
        : ["jpeg", "jpg", "png"];

    if (validExtensions.includes(fileExtension)) {
      setFile(droppedFile);
      if (handleNext) {
        handleNext();
      }
    } else {
      const validExtensionsString = validExtensions.join(", ");
      alert(`${validExtensionsString} 파일만 업로드 가능합니다.`);
    }

    setIsDragging(false);
  };

  return (
    <GreyContainer
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {children}
    </GreyContainer>
  );
};

export default DndBox;
