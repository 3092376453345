import { Popover, Stack, Typography } from "@mui/material";
import guideImage from "@image/guide_image.png";
import React, { ReactNode, useState } from "react";
import { GuideStep } from "../virtualFace/VirtualFaceStyle";
import { colors } from "@assets/styles/colors";
import { Help } from "@mui/icons-material";

interface ProgramGuideBoxProps {
  guide?: string;
  step: number;
  title: string;
  description: ReactNode;
  tooltip?: string;
}

const ProgramGuideBox = (props: ProgramGuideBoxProps) => {
  const { guide, step, title, description, tooltip } = props;

  // 툴팁
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "tooltip" : undefined;

  return (
    <Stack
      alignItems="center"
      sx={{
        flexDirection: {
          xs: "column",
          lg: "row",
        },
        gap: {
          xs: "20px",
          lg: "80px",
        },
      }}
    >
      <Typography fontSize={28} fontWeight={800}>
        Program Guide
      </Typography>
      <Stack
        gap="40px"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: {
            xs: "center",
            sm: "initial",
          },
        }}
      >
        <img
          src={guide ? guide : guideImage}
          alt="guide"
          width="170px"
          height="170px"
          style={{
            backgroundColor: colors.background,
            objectFit: "contain",
          }}
        />
        <Stack
          display="inline-flex"
          alignItems="flex-start"
          justifyContent={tooltip ? "space-between" : undefined}
          sx={
            tooltip
              ? undefined
              : {
                  gap: {
                    xs: "10px",
                    sm: "20px",
                  },
                }
          }
        >
          <Stack gap="5px" alignItems="flex-start">
            <GuideStep>Step {step}</GuideStep>
            <Typography fontSize={22} fontWeight={600}>
              {title}
            </Typography>
          </Stack>
          <Typography fontSize={18} color={colors.variant.grey150}>
            {description}
          </Typography>
          {tooltip && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                sx={{ color: colors.variant.grey150, cursor: "pointer" }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <Help />
                <Typography>자세히보기</Typography>
              </Stack>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      maxWidth: "400px",
                      padding: "10px",
                      borderRadius: "10px",
                      lineHeight: "18px",
                      fontSize: 14,
                      background: "#000",
                      color: "#fff",
                    },
                  },
                }}
              >
                {tooltip}
              </Popover>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProgramGuideBox;
