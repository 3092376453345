import { colors } from "@assets/styles/colors";
import { CircularProgress, Stack } from "@mui/material";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

interface S3ContentLoadProps {
  s3Url: string;
  type: "image" | "video";
}

const S3ContentLoad = (props: S3ContentLoadProps) => {
  const { s3Url, type } = props;
  const [url, setUrl] = useState("");

  const handleGetS3 = async (s3Url: string) => {
    try {
      const url = await Storage.get(s3Url);
      console.log(url);
      setUrl(url as string);
    } catch (error) {
      console.error("Error getting image from S3", error);
    }
  };

  useEffect(() => {
    handleGetS3(s3Url);
  }, [s3Url]);

  return url === "" ? (
    <Stack
      sx={{
        width: "100%",
        height: "50vh",
        backgroundColor: colors.background,
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  ) : type === "image" ? (
    <img
      src={url}
      alt={s3Url}
      loading="lazy"
      style={{
        width: "100%",
        height: "50vh",
        objectFit: "contain",
        backgroundColor: colors.background,
        boxShadow:
          "rgba(150, 150, 150, 0.16) 0px 10px 36px 0px, rgba(150, 150, 150, 0.06) 0px 0px 0px 1px",
      }}
    />
  ) : (
    <video
      controls
      src={url}
      style={{
        width: "100%",
        height: "50vh",
        objectFit: "contain",
      }}
    />
  );
};

export default S3ContentLoad;
