import styled from "styled-components";
import { Button, Input as MuiInput, Stack, Typography } from "@mui/material";
import { colors } from "@assets/styles/colors";
import { darken } from "polished";

export const RootContainer = styled("div")`
  width: 100%;
  min-height: 100vh;
`;
export const ContentsContainer = styled(Stack)`
  position: relative;
  padding: 40px 100px;
  min-height: calc(100vh - 80px);
  & > div {
    min-width: 1105px;
  }

  @media (max-width: 1200px) {
    & > div {
      min-width: auto;
    }
  }

  @media (max-width: 900px) {
    padding: 40px;
    min-height: calc(100vh - 60px);
  }
`;
export const GreyContainer = styled(Stack)`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: 40px;
  /* min-height: 430px; */
  min-height: 65vh;
  padding: 40px;
  background: ${colors.variant.grey50};
  border-radius: 40px;
`;
export const StyledInput = styled(MuiInput)`
  margin-top: 7px !important;
  &&:before {
    border-bottom-color: ${colors.variant.grey150} !important;
  }
  color: ${colors.variant.grey150} !important;
  ::placeholder {
    color: ${colors.variant.grey150} !important;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: ${colors.variant.grey150} !important;
    -webkit-box-shadow: 0 0 0px 1000px #2e2e2e inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${colors.variant.grey150} !important;
  }
`;
export const StyledButton = styled(Button)<{ background?: string }>`
  width: 125px;
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #fff !important;
  background: ${({ background }) => `${background || colors.point} !important`};
  border-radius: 10px !important;
  box-shadow: none;
  text-transform: none;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
`;
export const LineButton = styled(Button)<{
  background?: string;
  disabled?: boolean;
}>`
  cursor: pointer;
  background: ${({ background, disabled }) =>
    `${
      disabled
        ? darken(0.2, background || colors.point)
        : background || colors.point
    } !important`};
  border-radius: 8px !important;
  padding: 8px 16px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  border-bottom: 4px solid
    ${({ background, disabled }) =>
      `${
        disabled
          ? darken(0.3, background || colors.point)
          : darken(0.2, background || colors.point)
      } !important`};
  font-weight: 700;
  color: ${({ disabled }) =>
    disabled ? colors.variant.grey150 : "#fff"} !important;
  line-height: 26px;
`;
export const TextButton = styled(Typography)<{ selected?: boolean }>`
  font-weight: 600 !important;
  color: ${({ selected }) => (selected ? colors.point : "#fff")} !important;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${colors.point} !important;
  }
`;

// 헤더
export const HeaderBt = styled(Typography)<{ selected?: boolean }>`
  position: relative;
  font-weight: 600 !important;
  color: #fff;
  letter-spacing: -0.7px;
  text-transform: none;
  font-style: ${({ selected }) => (selected ? "italic" : "normal")};
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${colors.point};
  }
  &::before {
    content: ${({ selected }) => (selected ? "''" : "none")};
    display: ${({ selected }) => (selected ? "inline-block" : "none")};
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-70%);
    width: 7px;
    height: 7px;
    background: ${colors.point};
    margin-right: 5px;
    border-radius: 50%;
    vertical-align: middle;
  }
`;
